import React, { FC } from 'react';
import './ResultPane.scss';
import { IResultPaneProps } from './IResultPaneProps';
import Result from './Result/Result';


const ResultPane: FC<IResultPaneProps> = (props) => {
    let queryTextValue = props.queryText;
    return (
        <div  className="">
            {props.results.map((result,idx) =>  {
                return <Result key={`resultPaneParent-${idx}`} idx={idx} result={result} queryText={queryTextValue} />
            })}
        </div>
    );
}


export default ResultPane;
