import HeaderMounter from '../Components/SharedComponents/Header/HeaderMounter';
import FooterMounter from '../Components/SharedComponents/Footer/FooterMounter';
import Logo from '../svg/udirlogo';
import ReactDOM from 'react-dom/client';
import React from 'react';
import { LogWarning, Log } from '../utils/logging';

export enum SearchSite {
    LPV,
    FoR,
    Eksamensoppgaver,
    Regelverk,
    Global
}

async function setupHeaderAndFooter(searchSite: SearchSite) {
    // Checking if header/footer is cached
    let headerDataCached = sessionStorage.getItem('headerData');
    let footerDataCached = sessionStorage.getItem('footerData');
    let headerData; let footerData; let lowerKeysFooterData;
    
    if(headerDataCached && footerDataCached){
        Log("Using Cached Header and Footer");
        headerData = JSON.parse(headerDataCached || '{}');
        headerData = mapObjectHrefs(headerData);
        footerData = JSON.parse(footerDataCached || '{}');
        footerData = mapObjectHrefsFooter(footerData);
        lowerKeysFooterData = createCamelCaseKeys(footerData);
        HeaderMounter(searchSite, headerData);
        FooterMounter(undefined, lowerKeysFooterData);
        return;
    }

    const footerRoot = ReactDOM.createRoot(document.getElementById('footer') as HTMLElement);
    try {
        Log("Getting header and footer from REST");
        const menuData = await fetch('https://www.udir.no/api/data/lk20menu');
        const jsonMenuData = await menuData.json();
        headerData = jsonMenuData['header'];
        headerData = mapObjectHrefs(headerData);

        footerData = jsonMenuData['footer'];
        footerData = mapObjectHrefsFooter(footerData);
        lowerKeysFooterData = createCamelCaseKeys(footerData);
        
        HeaderMounter(searchSite, headerData);
        logoRenderer();
        FooterMounter(footerRoot, lowerKeysFooterData);
        
        sessionStorage.setItem('headerData', JSON.stringify(headerData));
        sessionStorage.setItem('footerData', JSON.stringify(footerData));
    } catch(e) {
        LogWarning("Failed to load dynamic footer data, using fallback");
        HeaderMounter(searchSite);
        logoRenderer();
        FooterMounter(footerRoot);
    }
}

function logoRenderer(){
    let logoNode = document.getElementsByClassName("header__logo")[0] as HTMLElement;
    logoNode.style.backgroundImage = "none";
    logoNode.style.width = "176px";
    logoNode.style.height = "53px";
    
    let logoImage = document.getElementsByClassName("header__print-logo")[0] as HTMLElement;
    if(logoImage && logoImage !== null){
        logoImage.setAttribute("alt","Utdanningsdirektoratet");
    }

    let logo = React.createElement(Logo);
    const logoNodeRoot = ReactDOM.createRoot(logoNode);
    logoNodeRoot.render(logo);
}

function mapObjectHrefsFooter(object: any){
    let stringObject = JSON.stringify(object);
    const regexp = /("href":"\/)/g;
    const replaceStringObject = stringObject.replace(regexp, '"href":"https://www.udir.no/');
    return JSON.parse(replaceStringObject);
}

function mapObjectHrefs(object: any) {
    object["linkToHomeUrl"] = "https://www.udir.no";
    let stringObject = JSON.stringify(object);
    const regexp = /("href":"\/)/g;
    const replaceStringObject = stringObject.replace(regexp, '"href":"https://udir.no/');
    return JSON.parse(replaceStringObject);
}

function createCamelCaseKeys(data: any) {
    let lowerData = {};
    if(data && typeof(data) !== 'object') {
        return data;
    }
    if (Array.isArray(data)) {
        return data.map(dataObject => {
            return createCamelCaseKeys(dataObject);
        });
    }
    else {
        Object.keys(data).forEach(key => {
            if (key) {
                const camelCase = key[0].toLowerCase() + key.slice(1);
                if (data[key] && typeof (data[key]) === "object") {
                    lowerData[camelCase] = createCamelCaseKeys(data[key])
                } else {
                    lowerData[camelCase] = data[key];
                }
            }
        });
        return lowerData;
    }
}


export default setupHeaderAndFooter;
