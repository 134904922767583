import React, { FC } from 'react';
import './Pagination.scss';
import { IPaginationProps } from './IPaginationProps';
import { SizeMe } from 'react-sizeme'



const Pagination: FC<IPaginationProps> = (props: IPaginationProps) => {
    return (
        <SizeMe>
        {({ size }) => {
            let shouldDisplayDotsLeft;
            let shouldDisplayDotsRight;
            let shouldDisplayArrowLeft = true;
            let shouldDisplayArrowRight = true;
            let numberOfButtons;
            let startIndex: number;
            const maxNumberOfDisplaySlots = getMaxNumberOfDisplaySlots(size.width);
            if(maxNumberOfDisplaySlots < props.numberOfPages) {
                shouldDisplayDotsLeft =  ((props.currentPage - Math.round(maxNumberOfDisplaySlots/2) + 1) > 1)
                shouldDisplayDotsRight = ((props.currentPage + Math.round(maxNumberOfDisplaySlots/2) - 1) < props.numberOfPages)
                if(shouldDisplayDotsLeft && shouldDisplayDotsRight) {
                    numberOfButtons = maxNumberOfDisplaySlots - 2;
                } else if (shouldDisplayDotsLeft || shouldDisplayDotsRight){
                    numberOfButtons = maxNumberOfDisplaySlots - 1;
                } else {
                    numberOfButtons = maxNumberOfDisplaySlots;
                }
                numberOfButtons = numberOfButtons > 0 ? numberOfButtons : 1;
                const topDisplayItem = (props.currentPage + Math.round(maxNumberOfDisplaySlots/2));
                if(topDisplayItem > props.numberOfPages) {
                    startIndex = props.numberOfPages - numberOfButtons + 1;
                } else if(props.currentPage - Math.floor(numberOfButtons / 2) > 1) {
                    startIndex = props.currentPage - Math.floor(numberOfButtons / 2);
                } else {
                    startIndex = 1;
                }
            } else {
                startIndex = 1;
                shouldDisplayDotsLeft = false;
                shouldDisplayDotsRight = false;
                numberOfButtons = props.numberOfPages;
            }

            if(props.currentPage === 1){
                shouldDisplayArrowLeft =  false;
            }else{
                shouldDisplayArrowLeft =  true;
            }
            if(props.currentPage === props.numberOfPages){
                shouldDisplayArrowRight =  false;
            }
            return (
                <nav className="paginationContainer" role="navigation" aria-label={`Flere resultater for ${props.searchQuery}`}>
                    <h2 className='is-hidden'>Paginering</h2>
                    <div className="flex-left">
                        {shouldDisplayArrowLeft &&
                            <button onMouseDown={preventFocus} 
                                    onClick={() => onArrowButtonClicked(props.currentPage, props.numberOfPages, false, props.onPageChanged)}
                                    className="arrowButton arrowButtonPrevious">
                                <span className="pageNavigationNextPrev">
                                    <svg className="icon-previous" focusable="false" width="23" height="13" viewBox="0 0 23 13"><g fill="none"><path d="M22.5 5.5l-8 5.5V0zM14 5.5H0"></path></g></svg>
                                </span>
                                <p className="arrowButtonText previous">Forrige side</p>
                            </button>
                        }
                    </div>
                    <div className="flex-center">
                        {shouldDisplayDotsLeft && <div className="pageButton dots pageButtonNotClickable">...</div>}
                        {new Array(numberOfButtons).fill('').map( (_,idx) => {
                            const pageNumber = idx + startIndex;
                            const isCurrentPage = pageNumber === props.currentPage;
                            const ariaCurrentPage = isCurrentPage ? 'page' : 'false';
                            const currentPageClassName = isCurrentPage ? "currentPageButton" : "";
                            return(
                                <button onMouseDown={preventFocus}
                                        aria-label={`Side ${pageNumber}`} 
                                        aria-current={ariaCurrentPage} 
                                        key={`pageButton-${pageNumber}`} 
                                        onClick={() => props.onPageChanged(pageNumber)} 
                                        className={`pageButton ${currentPageClassName}`}>
                                        {pageNumber}
                                </button>
                                );
                            })}
                        {shouldDisplayDotsRight && <div className="pageButton dots pageButtonNotClickable">...</div>}
                    </div>
                    <div className="flex-right">
                        {shouldDisplayArrowRight &&
                            <button onMouseDown={preventFocus} onClick={() => onArrowButtonClicked(props.currentPage, props.numberOfPages, true, props.onPageChanged)} className="arrowButton arrowButtonNext">
                                <p className="arrowButtonText next">Neste side</p>
                                <span className="pageNavigationNextPrev">
                                    <svg className="icon" focusable="false" width="23" height="13" viewBox="0 0 23 13"><g fill="none"><path d="M22.5 5.5l-8 5.5V0zM14 5.5H0"></path></g></svg>
                                </span>
                            </button>
                        }
                    </div>
                </nav>
            )
        }}
        </SizeMe>
    )
}

const preventFocus = (e) => {
    e.preventDefault();
}

const getMaxNumberOfDisplaySlots = (displayBoxWidth: number|null) =>  {
    //If the SizeMe box has yet to be rendered or erronously set to small
    if(!displayBoxWidth || displayBoxWidth < 40) {
        return 1
    }

    var arrowButtonWidth = 190;
    var numberButtonWidth = 28;
    let maxNumbersVal = Math.floor(((displayBoxWidth - (2 * arrowButtonWidth )) / numberButtonWidth));
    if(maxNumbersVal > 11){
        maxNumbersVal = 11;
    }
    if( /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        arrowButtonWidth = 46;
        numberButtonWidth = 45;
        maxNumbersVal = Math.floor(((displayBoxWidth - (2 * arrowButtonWidth )) / numberButtonWidth));
    }
    return maxNumbersVal;
}

const onArrowButtonClicked = (currentPage: number, numberOfPages: number,  nextButtonClicked: boolean, updatePageNumber: (pageNumber: number) => void)  => {
    let nextPage;
    if(nextButtonClicked) {
        nextPage = currentPage + 1 <= numberOfPages ? currentPage + 1 : numberOfPages;  //Only move forward if currentpage is not last page
    } else {
        nextPage = currentPage - 1 > 0 ? currentPage - 1 : 1                            //Only move back if currentpage is 1
    }
    updatePageNumber(nextPage);
}

export default Pagination;
