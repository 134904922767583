import headerProps from './staticHeaderData.json';
import { SearchSite } from '../../../utils/setupHeaderAndFooter';
import { Log } from '../../../utils/logging';

export default function mountHeader(site: SearchSite, headerProperties?: any) {
    let components = (window as any).UdirComponents;
    const headerData = headerProperties ? headerProperties : headerProps;
    headerData["breadcrumbs"] = getBreadCrumbs(site);
    Log(headerData);
    if(site === SearchSite.Global) {
        Log(headerData);
        headerData['search'] = undefined;
        headerData['searchButtonText'] = undefined;

    }
    (window as any).ReactDOM.render((window as any).React.createElement(components.Header, headerData), document.getElementById('header'))
}



function getBreadCrumbs(site: SearchSite) {
    let baseUrl = "https://www.udir.no";
    if(window.location.host === "localhost:3000"){
        baseUrl = "http://testdesk.udir.no";
    }else if(window.location.host === "udir-sok-utv.azurewebsites.net"){
        baseUrl = "https://www.udir.no"
    }else if(window.location.host === "qa-sokeresultat.udir.no"){
        baseUrl = "https://qadesk.udir.no"
    }

    if(site === SearchSite.LPV) {
        return JSON.parse('{"items":[{"href":"'+baseUrl+'","isSelected":false,"text":"Forside"},{"href":"'+baseUrl+'/laring-og-trivsel/","isSelected":false,"text":"Læring og trivsel"},{"href":"'+baseUrl+'/laring-og-trivsel/lareplanverket/","isSelected":false,"text":"Læreplanverket"},{"isSelected":true,"text":"Søk i læreplaner"}],"label":"Brødsmulesti ","languageTag":"nb","title":"Du er her:"}');
    } else if (site === SearchSite.FoR) {
        return JSON.parse('{"items":[{"href":"https://www.udir.no/","isSelected":false,"text":"Forside"},{"href":"https://www.udir.no/tall-og-forskning/","isSelected":false,"text":"Tall og forskning"},{"href":"","isSelected":true,"text":"Søk i forskning og statistikk"}],"label":"Brødsmulesti","languageTag":"nb","title":"Du er her:"}');
    } else if (site === SearchSite.Eksamensoppgaver) {
        return JSON.parse('{"items":[{"href":"https://www.udir.no/","isSelected":false,"text":"Forside"},{"href":"https://www.udir.no/eksamen-og-prover/","isSelected":false,"text":"Eksamen og prøver"},{"href":"https://www.udir.no/eksamen-og-prover/eksamen/","isSelected":false,"text":"Eksamen"},{"isSelected":true,"text":"Søk i eksamensoppgaver"}],"label":"Brødsmulesti","languageTag":"nb","title":"Du er her:"}');
    } else if(site === SearchSite.Global) {
        return JSON.parse('{"items":[{"href":"https://www.udir.no/","isSelected":false,"text":"Forside"},{"isSelected":true,"text":"Søk på udir.no"}],"label":"Brødsmulesti","languageTag":"nb","title":"Du er her:"}');
    } else if(site === SearchSite.Regelverk) {
        return JSON.parse('{"items":[{"href":"https://www.udir.no/","isSelected":false,"text":"Forside"},{"href":"https://www.udir.no/regelverk-og-tilsyn/","isSelected":false,"text":"Regelverk og tilsyn"},{"href":"","isSelected":true,"text":"Søk i regelverk"}],"label":"Brødsmulesti","languageTag":"nb","title":"Du er her:"}');
    }else {
        return {}
    }
}
