export const lpvfilterGroupOrder = [
    "fltypefiltermulti",
    "nivaa",
    "utdanningsprogram",
    "laereplanfagtype",
    "spraakmaalform",
    "status"
];

export const subInnholdstypeCheckBoxes = [
    "Kunnskapsløftet 2020",
    "Kunnskapsløftet 2006",
    "Fagets relevans og sentrale verdier",
    "Fagets relevans og verdier",
    "Kjerneelementer",
    "Tverrfaglige temaer",
    "Grunnleggende ferdigheter",
    "Kompetansemål",
    "Fagkode med vurdering",
    "Fagkode uten vurdering",
    "Fagkode for privatskole",
]

export const innholdstypeCheckBoxes = [
    "Læreplan",
    "Overordnet del",
    "Elementer i læreplanen LK20",
    "Fagkode"
]

export const nivaaCheckBoxes = [
    "Trinn",
    "Påbygning til generell studiekompetanse"
]

export const subNivaCheckBoxes = [
    "1. årstrinn",
    "1. trinn",
    "2. årstrinn",
    "2. trinn",
    "3. årstrinn",
    "3. trinn",
    "4. årstrinn",
    "4. trinn",
    "5. årstrinn",
    "5. trinn",
    "6. årstrinn",
    "6. trinn",
    "7. årstrinn",
    "7. trinn",
    "8. årstrinn",
    "8. trinn",
    "9. årstrinn",
    "9. trinn",
    "10. årstrinn",
    "10. trinn",
    "Videregående trinn 1",
    "Vg1",
    "Videregående trinn 2",
    "Vg2",
    "Videregående trinn 3",
    "Vg3",
    "Særløp",
    "Bygg- og anleggsteknikk",
    "Design og håndverk",
    "Elektro og datateknologi",
    "Frisør, blomster, interiør og eksponeringsdesign",
    "Håndverk, design og produktutvikling",
    "Helse- og oppvekstfag",
    "Informasjonsteknologi og medieproduksjon",
    "Naturbruk",
    "Restaurant- og matfag",
    "Salg, service og reiseliv",
    "Service og samferdsel",
    "Teknologi- og industrifag",
    "Idrettsfag",
    "Kunst, design og arkitektur",
    "Medier og kommunikasjon",
    "Musikk, dans og drama",
    "Studiespesialisering",
]

export const subUtdanningsprogramCheckBoxes = [
    "Bygg- og anleggsteknikk",
    "Design og håndverk",
    "Elektro og datateknologi",
    "Frisør, blomster, interiør og eksponeringsdesign",
    "Håndverk, design og produktutvikling",
    "Helse- og oppvekstfag",
    "Informasjonsteknologi og medieproduksjon",
    "Naturbruk",
    "Restaurant- og matfag",
    "Salg, service og reiseliv",
    "Service og samferdsel",
    "Teknologi- og industrifag",
    "Idrettsfag",
    "Kunst, design og arkitektur",
    "Medier og kommunikasjon",
    "Musikk, dans og drama",
    "Studiespesialisering",
]
export const fltypefiltermultiChechboxOrder = [
    "Læreplan",
    "Kunnskapsløftet 2020",
    "Kunnskapsløftet 2006",
    "Overordnet del",
    "Elementer i læreplanen LK20",
    "Fagets relevans og sentrale verdier",
    "Fagets relevans og verdier",
    "Kjerneelementer",
    "Tverrfaglige temaer",
    "Grunnleggende ferdigheter",
    "Kompetansemål"
]
export const nivaChechboxOrder = [
    "Trinn",
    "1. årstrinn",
    "1. trinn",
    "2. årstrinn",
    "2. trinn",
    "3. årstrinn",
    "3. trinn",
    "4. årstrinn",
    "4. trinn",
    "5. årstrinn",
    "5. trinn",
    "6. årstrinn",
    "6. trinn",
    "7. årstrinn",
    "7. trinn",
    "8. årstrinn",
    "8. trinn",
    "9. årstrinn",
    "9. trinn",
    "10. årstrinn",
    "10. trinn",
    "Videregående trinn 1",
    "Vg1",
    "Videregående trinn 2",
    "Vg2",
    "Videregående trinn 3",
    "Vg3",
    "Særløp"
]

export const utdanningsprogramCheckBoxOrder = [
    "VGO studieforberedende LK20",
    "Idrettsfag",
    "Kunst, design og arkitektur",
    "Medier og kommunikasjon",
    "Musikk, dans og drama",
    "Studiespesialisering",
    "VGO yrkesfag LK20",
    "Bygg- og anleggsteknikk",
    "Design og håndverk",
    "Elektro og datateknologi",
    "Frisør, blomster, interiør og eksponeringsdesign",
    "Håndverk, design og produktutvikling",
    "Helse- og oppvekstfag",
    "Informasjonsteknologi og medieproduksjon",
    "Naturbruk",
    "Restaurant- og matfag",
    "Salg, service og reiseliv",
    "Service og samferdsel",
    "Teknologi- og industrifag",
    "Videregående opplæring",
    "Påbygning til generell studiekompetanse"
]

export const statusSortOrder = [
    "Gjeldende",
    "Gjeldende for noen trinn", /* Gradvis innføring */
    "Gradvis utfasing",
    "Inkluder kommenede versjoner",
    "Inkluder utgåtte versjoner",
    "Kommende",
    "Utgått"

]

export const FoRFilterGroupOrder = [
    "rapportnivaa",
    "rapportutgiver",
    "publisertdato",
    "datoendret",
    "rapportaar",
    "rapportaardate"
];

export const RegelverkstolkningerFilterGroupOrder = [
    "Tema",
    "sidetype",
    "innholdstype",
    "gyldighet"
];

export const EksamenFilterGroupOrder = [
    "ExCatalogTypeName",
    "ExKL",
    "ExTestType",
    "ExPeriodName"
];

export const GlobalSokFilterGroupOrder = [
    "fltypefilter",
    "ExCatalogTypeName",
    "rapportinnholdstype",
    "innholdstypefilter",
    "datoendret"
];

export const DateEndretFilterOrder = [
    "I dag",
    "1 uke siden - i dag",
    "1 mnd - 1 uke siden",
    "1 år - 1 mnd siden",
    "Mer enn 1 år siden"
]

export const RegelverkInnholdstypeOrder = [
    "Paragrafer i lov",
    "Paragrafer i forskrift",
    "Tolkningsuttalelse",
    "Rundskriv",
    "Veiledning",
    "Artikkel"
]