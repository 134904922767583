/* eslint eqeqeq: "off",@typescript-eslint/no-unused-vars:off,no-redeclare:off,no-use-before-define:off,@typescript-eslint/no-unused-vars:off*/
import 'whatwg-fetch';
import IResponse from "../../Models/IResponse";
import IRefiners from "../../Models/IRefiners";
import IRefiner from "../../Models/IRefiner";
import { nivaChechboxOrder, fltypefiltermultiChechboxOrder, subNivaCheckBoxes, subInnholdstypeCheckBoxes, lpvfilterGroupOrder, utdanningsprogramCheckBoxOrder, subUtdanningsprogramCheckBoxes, statusSortOrder, innholdstypeCheckBoxes, nivaaCheckBoxes } from "../../utils/sortingLists";
import { ConvertStringToSearchToken, GetRESTData, getBaseSearchURL, getDigest, RemoveSpecialCharsFromQuery } from "../../utils/searchHelpers";
import { Log } from '../../utils/logging';
import { hostname } from 'os';



const selectedProperties = [
    'Path',
    'Url',
    'Title',
    'language',
    'formaal',
    'innholdstype',
    'innholdstypefilter',
    'fltypefilter',
    'datoendret',
    'description',
    'laereplankompetansemaalsett',
    'laereplanaarstrinn',
    'laereplannnotittel',
    'laereplansmjtittel',
    'laereplansmetittel',
    'laereplansmatittel',
    'laereplannobtittel',
    'laereplanengtittel',
    'HitHighlightedProperties',
    'HitHighlightedSummary',
    'kode',
    'status',
    'publisertstatus',
    'gyldigfratext',
    'gyldigtiltext',
    'nivaa',
    'spraakmaalform',
    'gradvisinnforingdato',
    'utdanningsprogramnavn',
    'utdanningsprogramurl',
    'fagnavn',
    'laereplannavn',
    'laereplankode',
    'laereplantilhorendefagkode',
    'innfoeringstekst',
    'TitleNormalized',
    'utdanningsprogram'
]

const properties = [
    "SourceName:Lareplanverket",
    "SourceLevel:SPSite"
]

const allProperties = [
    "SourceName:LareplanverketAll",
    "SourceLevel:SPSite"
]

export enum SearchSource {
    Default = "Default",
    All = "All"
}


export interface SearchQuery {
    queryString: string;
    pageNumber: number;
    source: SearchSource,
    refiners?: IRefiners[];
}

export async function getResults(query: SearchQuery): Promise<IResponse> {
    if (query.queryString === "") {
        Log("getSearchResults(): setting blank query to *");
        query.queryString = "*";
    }
    query.queryString = RemoveSpecialCharsFromQuery(query.queryString);

    let sortlist;
    let baseUrl = getBaseSearchURL();
    let selectedPropertiesString = encodeURI(selectedProperties.join(','));
    let propertiesString = query.source === SearchSource.Default ? encodeURI(properties.join(',')) : encodeURI(allProperties.join(','));
    let queryString = encodeURI(`${baseUrl}/_api/search/query?querytext='${query.queryString}'`);
    let startRow = (query.pageNumber - 1) * 10;
    let refinementFilter = encodeURI(generateRefinementFilters(query.refiners));
    let useQueryTemplateWhenFilterOnlySearch = false;
    if ((query.queryString === null || query.queryString === "" || query.queryString === "*") ) {
        //sortlist = "datoendret:descending";
        //sortlist = "TitleNormalized:ascending";
        sortlist= "fltypesublk:descending,TitleNormalized:ascending";
        if (refinementFilter && refinementFilter.indexOf("utdanningsprogram") !== -1){
            useQueryTemplateWhenFilterOnlySearch = true;
            sortlist = "Rank:descending";
        }
    } else {
        sortlist = null;
    }
    // let nonActiveFilter = refiners !== undefined ? getNonActiveFilters(refiners): "NOT(status:kommende) NOT(status:utgått)";
    queryString += `&selectproperties='${encodeURIComponent(selectedPropertiesString)}'`
    queryString += `&trimduplicates=false`
    queryString += `&culture=1044`
    queryString += `&enablequeryrules=true`
    queryString += `&processbestbets=true`
    queryString += `&refiners='fltypefiltermulti(sort=name/ascending)%2cspraakmaalform(sort=name/ascending)%2cnivaa(sort=name/ascending)%2cstatus(sort=name/ascending)%2cutdanningsprogram(sort=name/ascending)%2claereplanfagtype(sort=name/ascending)'`
    if (refinementFilter && refinementFilter !== "&refinementFilters='undefined'")
        queryString += refinementFilter;
    Log("----------------------NEW SEARCH ----------------------------------")
    Log(query.refiners);
    Log(generateRefinementFilters(query.refiners));
    queryString += `&properties='${propertiesString}'`
    queryString += `&hithighlightedproperties='formaal%2cdescription%2claereplanengtittel%2claereplantilhorendefagkode%2claereplantilhorendefagkodeutenvurdering'`
    queryString += `&clienttype='AllResultsQuery'`
    if (sortlist) {
        queryString += `&sortlist='${sortlist}'`;
    }
    else {
        queryString += `&sortlist='Rank:descending'`
    }
    if(useQueryTemplateWhenFilterOnlySearch){
        // when Utdanningsprogram-filter selected, give boost to Fagtype: Felles programfag (110), Valgfritt programfag (100)
        let refinersOrString = '(* XRANK(cb=100) laereplanfagtype:programfag) XRANK(cb=10) laereplanfagtype:felles'
        if(refinersOrString && refinersOrString.length > 0)
            queryString += `&querytemplate='${encodeURIComponent(refinersOrString)}'`
    }
    queryString += `&startrow=${startRow}`;

    let response;
    //if(baseUrl === "https://udirsok-dev.udir.no" || baseUrl === "https://qa-sok.udir.no" || baseUrl === "https://sok.udir.no"){
    if (false) {
        queryString += `&QueryTemplatePropertiesUrl='spfile://webroot/queryparametertemplate-Lareplanverket.xml'`;
        const digest = await getDigest(baseUrl);
        Log("using queryparametertemplate.xml");
        response = await fetch(queryString, {
            headers: {
                'Accept': 'application/json',
                'X-RequestDigest': digest
            }
        });
    } else {
        response = await fetch(queryString, {
            headers: {
                'Accept': 'application/json'
            }
        });
    }
    let results = await response.json();
    Log(results);
    let prossesedResults = GetRESTData(results, queryString, processRefiners);
    Log(prossesedResults);
    return prossesedResults;
}

export function generateRefinementFilters(refiners: IRefiners[] | undefined) {
    if (!refiners) {
        return "";
    }
    let refinerStrings: string[][] = [];
    refiners.forEach(refiner => {
        let checkedRefinerValues = refiner.Entries.filter(refinerValue => refinerValue.checked);
        let refinerEntries = refiner.Entries;
        let previousCheckedLevel0Index = -1;
        // Iterating refiner.Entries, and removing .checked for level0 when its level1-s are checked
        checkedRefinerValues.forEach((refinerEntry,index) => {
            refinerEntry.useInQuery = true;

            if(refinerEntry.level === 0 && refinerEntry.checked){
                previousCheckedLevel0Index = index;
            }
            if(refinerEntry.level === 1 && refinerEntry.checked){
                
                if(previousCheckedLevel0Index !== -1){
                    checkedRefinerValues[previousCheckedLevel0Index].useInQuery = false;
                }

            }

        });
        checkedRefinerValues = checkedRefinerValues.filter(refinerValue => 
                                                    (refinerValue.useInQuery || refinerValue.useInQuery === undefined));
        let checkedRefinerStrings = checkedRefinerValues.map(val => `${refiner.Name}:${val.RefinementToken}`);
        refinerStrings.push(checkedRefinerStrings);
    });
    refinerStrings = refinerStrings.filter(list => list.length > 0);

    if (refinerStrings.length === 0) {
        return "";
    } else if (refinerStrings.length === 1) {
        return `&refinementFilters='${mapRefinersToStrings(refinerStrings[0])}'`;
    } else {
        return `&refinementFilters='AND(${refinerStrings.map(refinerString => mapRefinersToStrings(refinerString)).join(',')})'`
    }
    
}

function mapRefinersToStrings(refinerElementStrings) {
    if (refinerElementStrings.length === 0) {
        return "";
    } else if (refinerElementStrings.length === 1) {
        return `${refinerElementStrings[0]}`;
    } else {
        return `OR(${refinerElementStrings.join(',')})`
    }
}

function processRefiners(searchRefiners) {
    if (null === searchRefiners) return null;

    let refiners = searchRefiners.Refiners
    let list: IRefiners[] = [];
    refiners.forEach(function (refiner) {
        list.push({
            Name: refiner.Name,
            Entries: refiner.Entries
        });
    });
    const sortedList = list.sort(sortRefinerGroups);
    //Special handling of the nivaa refiner: custom sorting and indentation (sub-checkboxes)
    let nivaaRefiner = sortedList.filter(refiner => refiner.Name === "nivaa")[0];
    if (nivaaRefiner) {
        nivaaRefiner.Entries.sort((a, b) => sortRefiners(a, b, nivaChechboxOrder));
        nivaaRefiner.Entries.forEach((nivaa: IRefiner) => {
            if (subNivaCheckBoxes.indexOf(nivaa.RefinementName) !== -1) {
                nivaa.indentation = true;
                nivaa.level = 1;
            } else{
                nivaa.level = 0;
            }
        });
    }
    //Special handling of the nivaa refiner: custom sorting and indentation (sub-checkboxes)
    let utdanningsprogramrefiner = sortedList.filter(refiner => refiner.Name === 'utdanningsprogram')[0];
    if (utdanningsprogramrefiner) {
        utdanningsprogramrefiner.Entries.sort((a, b) => sortRefiners(a, b, utdanningsprogramCheckBoxOrder));
        utdanningsprogramrefiner.Entries.forEach((utdanningsprogram: IRefiner) => {
            if(subUtdanningsprogramCheckBoxes.indexOf(utdanningsprogram.RefinementName) !== -1) {
                utdanningsprogram.indentation = true;
                utdanningsprogram.level = 1;
            }else{
                utdanningsprogram.level = 0;
            }
        });
    }
    //Special handling of the nivaa refiner: custom sorting and indentation (sub-checkboxes)
    let innholdstypeRefiner = sortedList.filter(refiner => refiner.Name === "fltypefiltermulti")[0];
    if (innholdstypeRefiner) {
        innholdstypeRefiner.Entries.sort((a, b) => sortRefiners(a, b, fltypefiltermultiChechboxOrder));
        innholdstypeRefiner.Entries.forEach((innholdstype: IRefiner) => {
            if (subInnholdstypeCheckBoxes.indexOf(innholdstype.RefinementName) !== -1) {
                innholdstype.indentation = true;
                innholdstype.level = 1;
                
            }else{
                innholdstype.level = 0;
            }
        });
    }
    return sortedList;
}
function sortRefinerGroups(a: IRefiners, b: IRefiners) {
    let aOrder = lpvfilterGroupOrder.indexOf(a.Name);
    let bOrder = lpvfilterGroupOrder.indexOf(b.Name);

    //Check if they are defined in the sortlist, if not, send them to the back
    if (aOrder !== -1 && bOrder !== -1) {
        return aOrder - bOrder;
    } else if (aOrder === -1 && bOrder === -1) {
        return a.Name.localeCompare(b.Name);
    }
    else if (aOrder === -1) {
        return 1
    } else if (bOrder === -1) {
        return 1
    } else {
        return a.Name.localeCompare(b.Name);
    }
}

function sortRefiners(a: IRefiner, b: IRefiner, orderList: string[]) {
    let aOrder = orderList.indexOf(a.RefinementName);
    let bOrder = orderList.indexOf(b.RefinementName);

    //Check if they are defined in the sortlist, if not, send them to the back
    if (aOrder !== -1 && bOrder !== -1) {
        return aOrder - bOrder;
    } else if (aOrder === -1 && bOrder === -1) {
        return a.RefinementName.localeCompare(b.RefinementName);
    }
    else if (aOrder === -1) {
        return 1
    } else if (bOrder === -1) {
        return -1
    } else {
        return a.RefinementName.localeCompare(b.RefinementName);
    }
}




