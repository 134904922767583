import React, { FunctionComponent } from 'react';
//import { getBaseSearchURL} from "../../utils/searchHelpers";


export interface IInformationMessageBoxProps {
    isDesktop: boolean;
    currentPage: string;
    informationMessage?: string;
}



export const InformationMessageBox: FunctionComponent<IInformationMessageBoxProps> = ({isDesktop, currentPage, informationMessage})  => {
    let displayAlertMsg = false;
    if(informationMessage){
        displayAlertMsg = true;
    }
    return (<div className={displayAlertMsg ? "alert-placeholder" : "alert-placeholder is-hidden"}>
                <div className="alert-block">
                    <span className="alert-global"></span>
                    <span className={isDesktop ? "infoMessage" : "infoMessageMobile"}>{informationMessage}</span>
                </div>
            </div>
    );
}

