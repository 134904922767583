import { GetRESTData, getBaseSearchURL, getDigest, RemoveSpecialCharsFromQuery} from "../../utils/searchHelpers";
import IRefiners from "../../Models/IRefiners";
import {GlobalSokFilterGroupOrder, DateEndretFilterOrder } from '../../utils/sortingLists';
import {Log} from '../../utils/logging';

import subWeeks  from 'date-fns/subWeeks';
import subYears  from 'date-fns/subYears';
import subMonths  from 'date-fns/subMonths';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import differenceInYears from 'date-fns/differenceInYears';
import format from 'date-fns/format';


const selectedProperties = [
    "Url",
    "title",
    "udirtitle",
    "formaal",
    "innholdstype",
    "datoendret",
    "description",
    "status",
    "kode",
    "language",
    "laereplannavn",
    "laereplanaarstrinn",
    'laereplansmjtittel',
    'laereplansmetittel',
    'laereplansmatittel',
    "utdanningsprogramnavn",
    "utdanningsprogramkode",
    "vedleggtittel",
    "vedleggurl",
    "HitHighlightedProperties",
    "HitHighlightedSummary",
    "tittelstortdokument",
    "prosessideider",
    "prosessidenavn",
    "ExCourseCode",
    "ExCourseType",
    "ExAnsvarstype",
    "ExKL",
    //"ExCatalogName",
    "ExPeriodName",
    //"ExDocumentRepositoryCode",
    //"ExUrl",
    //"ExUrlForb",
    //"ExUrlEksamen",
    //"ExFilename",
    //"ExFilenameForb",
    //"ExFileExtension",
    //"ExFileExtensionForb",
    "ExMaalform",
    //"ExIsPasswordProtected",
    "ExCourseLaereplanURL",
    //"ExSPSiteURL",
    "ContentSource",
]

const properties = [
    "SourceName:Local SharePoint Results Udir",
    "SourceLevel:SPSite"
]

export enum DateInterval {
    MoreThanAYearAgo,
    OneYearToOneMonth,
    OneMonthToOneWeek,
    OneWeekToToday,
    Today
}

export async function getResults(query: string, pageNumber: number, refiners?: IRefiners[]) {
    //Getting different search url based on which environment we currently are in
    const baseUrl = getBaseSearchURL();

    //Setting default query to be all items
    let queryText = query !== "" ? query : "";

    //Not doing search if query is blank
    if(queryText === ''){
        return {
            query: '',
            queryText: '',
            elapsedTime: null,
            suggestion: null,
            spellingSuggestion: '',
            resultsCount: 0,
            totalResults: 0,
            totalResultsIncludingDuplicates: 0,
            items: [],
            bestbets: [],
            resultblocks: [],
            refiners: null,
            modifiedQuery: '',
            piPageImpression: '',
            piPageImpressionBlockType: -1,
            sourceId: '',
        }
    }
    queryText = RemoveSpecialCharsFromQuery(queryText);
    let selectedPropertiesString = encodeURI(selectedProperties.join(','));
    let propertiesString = encodeURI(properties.join(','));
    let queryString = encodeURI(`${baseUrl}/_api/search/query?querytext='${queryText}'`);
    let startRow = (pageNumber - 1) * 10;
    let refinementFilter = encodeURI(generateRefinementFilters(refiners));
    queryString += `&selectproperties='${encodeURIComponent(selectedPropertiesString)}'`
    queryString += `&trimduplicates=false`
    queryString += `&culture=1044`
    queryString += `&enablequeryrules=true`
    queryString += `&processbestbets=true`
    //queryString += `&refiners='innholdstypefilter%2cfltypefilter%2cdatoendret(discretize%3Dmanual%2F2018-09-05%2F2019-08-05%2F2019-08-29%2F2019-09-05)'`
    queryString += `&refiners='GlobalsokType%2cdatoendret` + getDateIntervalsForRESTQuery() + `'`
    queryString += refinementFilter;
    queryString += `&properties='${propertiesString}'`
    queryString += `&hithighlightedproperties='Title,formaal,description'`
    queryString += `&clienttype='AllResultsQuery'`
    queryString += `&sortlist='Rank:descending'`;
    queryString += `&startrow=${startRow}`;
    let response;
    //if(baseUrl === "https://udirsok-dev.udir.no" || baseUrl === "https://qa-sok.udir.no" || baseUrl === "https://sok.udir.no"){
    if(false){
        queryString += `&QueryTemplatePropertiesUrl='spfile://webroot/queryparametertemplate.xml'`;
        const digest = await getDigest(baseUrl);
        Log("using queryparametertemplate.xml");
        response = await fetch(queryString, {
            headers: {
                'Accept': 'application/json',
                'X-RequestDigest': digest
            }
        });
    }else{
        response = await fetch(queryString, {
            headers: {
                'Accept': 'application/json'
            }
        });
    }
    let results = await response.json();
    let prossesedResults = GetRESTData(results, queryString, processRefiners);
    Log(prossesedResults)
    return prossesedResults;
}


export function generateRefinementFilters(refiners: IRefiners[] | undefined) {
    if(!refiners) {
        return "";
    }
    let refinerStrings: string[][] = [];
    refiners.forEach(refiner => {
        let checkedRefinerValues = refiner.Entries.filter(refinerValue => refinerValue.checked);
        let checkedRefinerStrings = checkedRefinerValues.map(val => `${refiner.Name}:${val.RefinementToken}`)
        refinerStrings.push(checkedRefinerStrings);
    });
    refinerStrings = refinerStrings.filter(list => list.length > 0);

    if(refinerStrings.length  === 0) {
        return "";
    } else if(refinerStrings.length === 1) {
        return `&refinementFilters='${mapRefinersToStrings(refinerStrings[0])}'`;
    } else {
        return `&refinementFilters='AND(${refinerStrings.map( refinerString => mapRefinersToStrings(refinerString)).join(',')})'`
    }

}

function getDateIntervalsForRESTQuery() {
    var rangeString = "(discretize=manual";
    var today = new Date();

    // Bucket 1
    //var lastYear = moment().subtract(1, 'year').format('YYYY-MM-DD');
    var lastYear = format(subYears(today, 1),('yyyy-MM-dd'));
    rangeString += "/" + lastYear;

    // Bucket 2
    var lastMonth = format(subMonths(today, 1),('yyyy-MM-dd'));
    rangeString += "/" + lastMonth;

    // Bucket 3
    var lastWeek = format(subWeeks(today, 1), ('yyyy-MM-dd'));
    rangeString += "/" + lastWeek;

    // Bucket 4
    var now = format(today, 'yyyy-MM-dd');
    rangeString += "/" + now;




    rangeString += ")";
    Log(rangeString);
    Log(encodeURIComponent(rangeString));

    return encodeURIComponent(rangeString);
}

function mapRefinersToStrings(refinerElementStrings) {
    if(refinerElementStrings.length === 0) {
        return "";
    } else if(refinerElementStrings.length === 1) {
        return `${refinerElementStrings[0]}`;
    } else {
        return `OR(${refinerElementStrings.join(',')})`
    }
}

function processRefiners(searchRefiners) {
    if (null === searchRefiners) return null;

    let refiners = searchRefiners.Refiners
    let list:IRefiners[] = [];
    refiners.forEach(function (refiner) {
        if(refiner.Name === 'datoendret') {
            list.push(processDateRefiners(refiner));
        } else {
            list.push({
                Name: refiner.Name,
                Entries: refiner.Entries
            });
        }
    });
    const sortedList = list.sort(sortRefinerGroups);
    return sortedList;
}

function processDateRefiners (refiner: any): IRefiners {
    refiner.Entries = refiner.Entries.filter(entry => entry.RefinementCount !== '0')
    refiner.Entries.forEach(entry => {
        let dateInterval = getDateInterval(entry.RefinementToken);
        switch(dateInterval) {
            case DateInterval.Today:
                entry.RefinementName = 'I dag';
                break;
            case DateInterval.OneWeekToToday:
                entry.RefinementName = '1 uke siden - i dag';
                break;
            case DateInterval.OneMonthToOneWeek:
                entry.RefinementName = '1 mnd - 1 uke siden';
                break;
            case DateInterval.OneYearToOneMonth:
                entry.RefinementName = '1 år - 1 mnd siden';
                break;
            case DateInterval.MoreThanAYearAgo:
                entry.RefinementName = 'Mer enn 1 år siden';
                break;
        }
    })
    refiner.Entries.sort((a, b) => DateEndretFilterOrder.indexOf(a.RefinementName) - DateEndretFilterOrder.indexOf(b.RefinementName));
    return refiner;
}

function getDateInterval(dateToken: string): DateInterval | undefined {
    //const dateRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z/g
    const dateRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]{7})?Z/g
    let matches = dateToken.match(dateRegex);
    if(!matches) return;

    if(matches.length === 1) {
        if(dateToken.indexOf('min') > -1) {
            return DateInterval.MoreThanAYearAgo;
        } else if(dateToken.indexOf('max') > -1) {
            return DateInterval.Today;
        }
    } else if(matches.length === 2){
        let fromDate = new Date(matches[0]);
        let today = new Date();
        if(differenceInWeeks(fromDate, today) === -1) {
            return DateInterval.OneWeekToToday;
        } else if(differenceInMonths(fromDate, today) === -1) {
            return DateInterval.OneMonthToOneWeek;
        } else if(differenceInYears(fromDate, today) === -1) {
            return DateInterval.OneYearToOneMonth;
        }
        else{
            Log("Could not map date interval in getDateInterval().");
            /*
            if(isAfter(fromDate,subMonths(today,1)) && isBefore(toDate, subDays(today,7))){
                return DateInterval.OneMonthToOneWeek;
            }
            */
        }
    }
}




function sortRefinerGroups(a: IRefiners, b: IRefiners) {
    return GlobalSokFilterGroupOrder.indexOf(a.Name) - GlobalSokFilterGroupOrder.indexOf(b.Name);
}
