import React, { FunctionComponent } from 'react';
import './FiltersSelected.scss';
import { IFiltersSelectedProps } from './IFiltersSelectedProps';
import IRefiners from "../../../Models/IRefiners";



export const FiltersSelected: FunctionComponent<IFiltersSelectedProps> = ({ isDesktop, filters, results }) => {
  if (filters && filters !== undefined) {
    const activeFilters = selectedFilters(filters);
    let filtertext = "Valgte filtre: ";
    if(results && results?.totalResults === 0 && results?.totalResultsIncludingDuplicates === 0){
      return <div className="hide"></div>
    }
    if (activeFilters && activeFilters !== undefined) {
      return <span className={isDesktop ? "filterChoices" : "filterChoicesMobile"}>{filtertext}&ensp;<strong>{activeFilters.toLowerCase()}</strong></span>
    }
    return <div className="hide"></div>
  }
  return <div className="hide"></div>
}

function selectedFilters(localFilters: IRefiners[]) {
  let activeFilters: string[][] = [];
  localFilters.forEach(filter => {
    let checkedFilters = filter.Entries.filter(val => val.checked === true);
    let filterNames = checkedFilters.map(val => ` ${val.RefinementName}`)
    activeFilters.push(filterNames);
  });
  activeFilters = activeFilters.filter(list => list.length > 0);

  if (activeFilters.length === 0) {
    return "";
  }
  else if (activeFilters.length === 1) {
    return `${activeFilters[0]}`;
  }
  else {
    return `${activeFilters}`;
  }
}


export default FiltersSelected;