import React from 'react';
import './SearchBox.scss';
import { ISearchBoxProps } from './ISearchBoxProps';
import { ISearchBoxState } from './ISearchBoxState';
import { getUrlParameter } from '../../../utils/urlParamHelper';
import getSearchSuggestions from '../../../utils/searchsuggestions';
import { Log } from '../../../utils/logging';

export default class SearchBox extends React.Component<ISearchBoxProps, ISearchBoxState> {
    public constructor(props) {
        super(props);
    }
    public componentDidMount() {
        if (this.props.initialQuery) {
            (document.getElementById('searchBoxInput') as HTMLInputElement).value = this.props.initialQuery;
            this.setState({
                currentQuery: this.props.initialQuery,
                showAutocompletePanel: false,
                searchsuggestions: [],
                cursor: -1
            });
        }
        if(this.props.autocomplete){
            // Load autocomplete data
            Log("Loading autocomplete data - this.props.autocomplete:");
            this.getAndSaveQuerySuggestions(this.props.initialQuery, 7);
        }else{
            // No autocomplete data
            Log("Autocomplete is deactivated");
        }

        // Adding click event listener to handle cliks outside SearchBox
        document.addEventListener('click', this.handleOutsideClick);
    }
    public componentDidUpdate() {
        if (this.props.newQuery) {
            (document.getElementById('searchBoxInput') as HTMLInputElement).value = this.props.newQuery;
            this.setState({
                currentQuery: this.props.newQuery
            });
        }
    }
    public handleOutsideClick = (event) => {
        // If the event target doesn't have class "searchBoxInput"
	    if (!event.target.classList.contains('searchBoxInput')){
            this.setState({
                showAutocompletePanel: false
            });
        }
      }
    public render() {
        let showResetButtonClass = this.state && this.state.currentQuery && this.state.currentQuery.length > 0 ? 'show' : '';
        let showAutocompletePanel = this.state && this.state.showAutocompletePanel;
        let isAutocomplete = this.props.autocomplete;
        if (showResetButtonClass === '') {
            let queryInUrl = getUrlParameter('k');
            if (queryInUrl && queryInUrl.length > 0) {
                showResetButtonClass = 'show';
            }
        }
        let placeholderText = this.props.placeholderText;
        if (placeholderText === undefined) {
            placeholderText = 'Skriv inn søkeord';
        }
        return (
            <div className="searchBoxOuterContainer">
                <div className="searchBoxContainer">
                    <input
                        //ref={(input) => this.text = input}
                        id="searchBoxInput"
                        defaultValue={this.props.initialQuery}
                        className="searchBoxInput"
                        onKeyDown={e => this.handleKeyDown(e, this.props.onSearch)}
                        onKeyUp={e => this.handleKeyUp(e, this.props.onSearch)}
                        //onFocus={e => this.handleSearchBoxOnFocus(e, showAutocompletePanel)}
                        onClick={e => this.handleSearchBoxOnFocus(e, showAutocompletePanel)}
                        //onBlur={e => this.handleSearchBoxOnBlur(e, showAutocompletePanel)}
                        type="search"
                        placeholder={placeholderText}
                        autoComplete="off"
                        aria-label="Søk"
                    />
                    <span className="resetHolder">
                        <button className={`resetButton ${showResetButtonClass}`} 
                                onClick={e => this.resetButtonClicked(e)}
                                aria-label="Tøm"
                                type="reset"
                        ></button>
                    </span>
                    <button
                        className="searchBoxButton"
                        type="submit"
                        onClick={e => this.handleSearchBoxButtonClicked(e, this.props.onSearch)}
                        onMouseDown={(e) => e.preventDefault()}
                    >
                        SØK
                    </button>
                </div>
                {isAutocomplete && showAutocompletePanel && (
                    this.renderAutocomplete(this.state.searchsuggestions)
                )}
            </div>
        );
    }
    public renderAutocomplete = (searchsuggestions: any) => {
        if(this.state.currentQuery !== ""){
            searchsuggestions = this.state.searchsuggestions;
            if(searchsuggestions && searchsuggestions.length > 0){
                return(
                    <div className="autocompleteContainer">
                        <ul className="autocompleteList">
                            {searchsuggestions && searchsuggestions.map((suggestion, idx) => {
                                return this.renderAutocompleteListElement(suggestion, idx);
                            })}
                        </ul>
                    </div>
                )
            }
        }
    }
    
    public createMarkup(html){
        return {__html: html};
    }
    public renderAutocompleteListElement = (searchsuggestion: any, idx: number) => {
        searchsuggestion = searchsuggestion.replace(this.state.currentQuery.toLowerCase(), "<strong>"+ this.state.currentQuery.toLowerCase() +"</strong>");
        return(
            <li className={`autocompleteListElement ${this.state.cursor === idx ? 'active' : ''}`} key={`autocompleteListElementGroup-${idx}`} onClick={e => this.handleAutocompleteElementClicked(e)}>
                <div className="autocompleteListElementText" 
                    dangerouslySetInnerHTML={this.createMarkup(searchsuggestion)}
                >
                </div>
            </li>
        )
    }
    public static getSearchBoxQuery(){
        return (document.getElementById('searchBoxInput') as HTMLInputElement).value;
    }
    
    private handleSearchBoxButtonClicked = (e: any, onSearch: any) => {
        this.setState({
            showAutocompletePanel: false
        });
        onSearch((document.getElementById('searchBoxInput') as HTMLInputElement).value, false)
    }
    private handleAutocompleteElementClicked = (e: any) => {
        Log("handleAutocompleteElementClicked");
        let clickedAutocompleteQuery = e.currentTarget.textContent;
        (document.getElementById('searchBoxInput') as HTMLInputElement).value = clickedAutocompleteQuery;
        this.setState({
            currentQuery: clickedAutocompleteQuery,
            showAutocompletePanel: false
        });
        this.props.onSearch(clickedAutocompleteQuery, false);
        this.getAndSaveQuerySuggestions(clickedAutocompleteQuery, 7);
    }

    private resetButtonClicked = (e: any) => {
        (document.getElementById('searchBoxInput') as HTMLInputElement).value = '';
        (document.getElementById('searchBoxInput') as HTMLInputElement).focus();
        this.setState({
            currentQuery: ''
        });
        this.props.onSearch('', false);
    };

    public updateSearchBoxQuery = () => {
        let activeQuery = this.state.searchsuggestions[this.state.cursor];
        if(this.state.cursor === -1){
            activeQuery = this.state.currentQuery;
        }
        (document.getElementById('searchBoxInput') as HTMLInputElement).value = activeQuery;
    }
    private handleKeyDown = (e: any, onSearch: any) => {
        // TODO: Setting cursor to end of input text

        if (e.key === 'Enter') {
            this.setState({
                showAutocompletePanel: false,
                cursor: -1
            });
            onSearch(e.target.value, false);
            return;
        }else if(e.key === 'Escape' || e.key === 'Tab'){
            this.setState({
                showAutocompletePanel: false
            });
        }else{
            this.setState({
                showAutocompletePanel: true
            });
        }

        // Handle arrow up and arrow down if autocomplete is activated
        if (e.key === 'ArrowDown' && this.state.cursor < this.state.searchsuggestions.length - 1) {
            this.setState( prevState => ({
                cursor: prevState.cursor + 1
              }), this.updateSearchBoxQuery)
        }
        else if (e.key === 'ArrowDown' && this.state.cursor === this.state.searchsuggestions.length - 1) {
            // At the bottom - move to top
            this.setState(({
                cursor: -1
              }), this.updateSearchBoxQuery)
        }
        else if (e.key === 'ArrowUp' && this.state.cursor >= 0) {
            e.preventDefault();
            this.setState( prevState => ({
                cursor: prevState.cursor - 1
            }), this.updateSearchBoxQuery)
        }
        else if (e.key === 'ArrowUp' && this.state.cursor === -1) {
            // At the top - move to bottom
            e.preventDefault();
            this.setState(({
                cursor: this.state.searchsuggestions.length - 1
            }), this.updateSearchBoxQuery)
        }
    };

    private handleKeyUp = (e: any, onSearch: any) => {
        Log("Handle keyup");
        
        if (this.ignoreKeyUp(e)) {
            return;
        }

        //Getting search suggestions
        let query = (document.getElementById('searchBoxInput') as HTMLInputElement).value;
        this.setState({
            currentQuery: query
        });
        if(query && query!==""){
            this.getAndSaveQuerySuggestions(query, 7);
            this.setState({
                cursor: -1
            });
        }else{
            this.setState({
                searchsuggestions: []
            });
        }
        
        if (!this.props.searchAsYouType) {
            return;
        }
        
        // Search-as-you-type when query is 3 characters or more
        if (query.length >= 3) {
            onSearch(e.target.value, true);
        }
    };

    private handleSearchBoxOnFocus = (e: any, showAutocompletePanel: boolean) => {
        this.setState({
            showAutocompletePanel: true
        });
    }
    private handleSearchBoxOnBlur = (e: any, showAutocompletePanel: boolean) => {
        this.setState({
            showAutocompletePanel: false
        });
    }

    private ignoreKeyUp = (e: any) => {
        if (e.key === 'Shift' || e.key === 'Control' || e.key === 'Alt' || e.key === 'AltGraph' || e.key === 'CapsLock' || e.key === 'Meta') {
            return true;
        }
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            return true;
        }
        if (e.key === 'Escape' || e.key === 'ContextMenu' || e.key === 'PageDown' || e.key === 'PageUp') {
            return true;
        }
        if (e.key === 'MediaPlayPause' || e.key === 'AudioVolumeMute' || e.key === 'AudioVolumeDown' || e.key === 'AudioVolumeUp') {
            return true;
        }
        if (e.key === 'PrintScreen' || e.key === 'Home' || e.key === 'End' || e.key === 'Delete') {
            return true;
        }
        if (e.key === 'Tab') {
            return true;
        }
        return false;
    };

    private getAndSaveQuerySuggestions(query, numhits) {
        if (!this.props.autocomplete) return;
        
        // Creating an autocomplete key for a specific search site and a specific query
        let autocompleteKey = "autocomplete-" + this.props.searchVertical + "-" + query;
        
        // Checking if we have autocompleteKey in localStorage
        const storedAutocompleteJSON = sessionStorage.getItem(autocompleteKey);
        if (storedAutocompleteJSON) {
            const storedAutocomplete: string[] = JSON.parse(storedAutocompleteJSON);
            this.setState({
                searchsuggestions: storedAutocomplete
            });
            return;
        }
        
        // Getting search suggestions from service (Autocomplete.ashx)
        let suggestions = getSearchSuggestions(query, numhits, this.props.searchVertical);
        suggestions.then(function (this: SearchBox, result: string[]) {
            if (result) {
                sessionStorage.setItem(autocompleteKey, JSON.stringify(result));
                this.setState({
                    searchsuggestions: result
                });
            }
        }.bind(this))
        .catch(function (error) {
            Log('Error fetching search suggestions: ' + error);
        });
    }
    
}
