import * as React from 'react';
import IGlobalSokProps from './IGlobalSokProps';
import Pagination from '../SharedComponents/Pagination/Pagination';
import ResultPane from './ResultPane/ResultPane';
import NoResultPane from '../SharedComponents/NoResultPane/NoResultPane';
import Filter from '../SharedComponents/Filter/Filter';
import SearchBox from '../SharedComponents/SearchBox/SearchBox';
import Navigation from '../SharedComponents/Navigation/Navigation';
import FiltersSelected from '../SharedComponents/FiltersSelected/FiltersSelected';
import './GlobalSok.scss'
import { Helmet } from 'react-helmet';
import { addNonActiveFilters, getFilterNames } from '../../utils'
import { getResults } from './getResults';
import IRefiners from '../../Models/IRefiners';
import { DisplayFormat } from '../../Models/DisplayFormat';
import { SearchPageLayout } from '../../Models/SearchLayout';
import { Resultnumber } from '../SharedComponents/Resultnumber';
import { MoveToTop, toggleToTopComponent } from '../SharedComponents/MoveToTop/MoveToTop';
import { SuggestionBox } from '../SharedComponents/SuggestionBox';
import { InformationMessageBox } from '../SharedComponents/InformationMessageBox';
import { getUrlParameter, setUrlParameter, removeAllFilterParams, getFilterParametersFromUrl, removeHashFromUrl } from '../../utils/urlParamHelper';
import { ensureSelectedFilterValues, getNumberOfActiveFilters } from '../../utils/refinerHelpers';
import { getInfoMsg } from '../../utils/searchHelpers';
import { getCurrentDisplayFormat } from '../../utils/generalHelpers';
import { Log } from '../../utils/logging';
import IGlobalSokState from './IGlobalSokState';
//import EksamensPane from './EksamensPane/EksamensPane';
import { IGlobalResult } from '../../Models/IResult';

export default class GlobalSok extends React.Component<IGlobalSokProps, IGlobalSokState> {
    private resultPerPage = 10;
    private refinementFilters = ['GlobalsokType','datoendret'];
    private queryParameter = "query";

    public constructor(props: IGlobalSokProps) {
        super(props);
        // Removing hash (#) from URL if exists
        if(window.location.hash && window.location.hash !== ""){
            removeHashFromUrl(window.location.href);
        }
        //Set up initial querydata from parameters
        let queryParameter = getUrlParameter(this.queryParameter);
        if(getUrlParameter("k") !== undefined){
            this.queryParameter = "k"
            queryParameter = getUrlParameter(this.queryParameter);
        }
        const initialPageNumber = getUrlParameter('page');
        const filterParameters = getFilterParametersFromUrl(this.refinementFilters);
        this.state = {
            currentPageNumber: initialPageNumber !== undefined ? Number(initialPageNumber) : 1,
            initialQuery: queryParameter,
            displayFormat: getCurrentDisplayFormat(),
            initialPageNumber: initialPageNumber !== undefined ? Number(initialPageNumber) : undefined,
            initialFilters: filterParameters ? filterParameters : undefined
        }
    }

    public componentDidMount() {
        window.addEventListener("resize", _ => this._onWindowResize());
        window.onpopstate = () => this.onStatePopped();
        this.setupAndRunInitialQuery();
        getInfoMsg().then(result => {
            if(result){
                if(result.global){
                    this.setState({ informationMessage: result.global.title });
                }
                for (var i = 0; i < result.length; i++) {
                    if(result[i].hasOwnProperty("global")){
                        this.setState({ informationMessage: result[i].global.title });
                        break;
                    }
                }
            }
        });
    }

    public componentDidUpdate() {
        toggleToTopComponent(this.state.results && this.state.results.items.length > 0);
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", _ => this._onWindowResize());
        this._onWindowResize();
    }

    public render() {
        const numberOfResults = this.findNumberOfResults();
        const numberOfPages = this.findNumberOfPages();
        const numberOfActiveFilters = getNumberOfActiveFilters(this.state.filter, this.refinementFilters);
        const isDesktop = this.state.displayFormat === DisplayFormat.Desktop;
        const searchLayout = SearchPageLayout.FilterLeftResultsRight;
        const searchboxClass = (isDesktop && searchLayout===0) ? "searchBoxBanner filterLeftAligned" : "searchBoxBanner";
        const resultinformationClass = (isDesktop && searchLayout===0) ? "resultInformationRow right" : "resultInformationRow";
        const filterClass = (isDesktop && searchLayout===0) ? "leftBar filter" : "rightBar filter";
        const searchresultsClass = (isDesktop && searchLayout===0) ? "rightBar results" : "leftBar results";
        const shouldDisplayFilters =  this.state.results && this.state.results.items && this.state.results.items.length > 0;
        const showAutocomplete = true;
        const shouldAddNoIndex = ( numberOfActiveFilters > 0 || (getUrlParameter('query') !== undefined) );
        return (
            <div id="main-content" className="searchContainer">
                <Helmet>
                    {shouldAddNoIndex && <meta name="robots" content="noindex" />}
                </Helmet>
                <div className={`${searchboxClass}`}>
                    <div className="leftBarSearchBox">
                        <h1 className="searchHeader">Søk på udir.no</h1>
                        <SearchBox 
                            initialQuery={this.state.initialQuery ? this.state.initialQuery : ""} 
                            onSearch={(query, searchAsYouType) => this.onSearch(query,searchAsYouType)} 
                            newQuery={this.state.newStateQuery} 
                            placeholderText="Søk på hele udir.no"
                            autocomplete={showAutocomplete} 
                            searchVertical="All"/>
                        <Navigation searchQuery={this.state.searchboxQuery} />
                    </div>
                    <div className="rightBarSearchBox"></div>
                </div>
                <div className={`${resultinformationClass}`}>
                    <SuggestionBox
                        query = {this.state.query}
                        searchboxQuery = {this.state.searchboxQuery}
                        erronousQuery = {this.state.erronousQuery}
                        querySuggestion = {this.state.querySuggestion}
                        emptyFilters = {() => this.emptyFilters()}
                        initialFilters = {this.state.initialFilters}
                        searchWithoutFiltersHasResults = {this.state.searchWithoutFiltersHasResults}
                        isSearchingWithDeactivatedFilters = {this.state.isSearchingWithDeactivatedFilters}
                        results = {this.state.results}
                    />
                    {isDesktop && <Resultnumber currentPageNumber={this.state.currentPageNumber} isDesktop={isDesktop} numberOfResults={numberOfResults} resultPerPage={this.resultPerPage} /> }
                    {isDesktop && this.state.filter && <FiltersSelected filters={this.state.filter} isDesktop={isDesktop} />}
                </div>
                <div className="resultContainer">
                    <div className={`${filterClass}`}>
                        {numberOfActiveFilters > 0 && shouldDisplayFilters && <span className="emptyFilterLink"><button onClick={() => this.emptyFilters()}>{`Tøm filtre (${numberOfActiveFilters})`}</button></span>}
                        {shouldDisplayFilters && this.state.filter && <Filter getFilterDisplayName={(filterName) => this.getFilterDisplayName(filterName)} refiners={this.state.filter} onFilterSelected={this.onFilterChanged.bind(this)} onAllFilterClicked={this.onAllFilterClicked.bind(this)} displayFormat={this.state.displayFormat} />}
                        {/* this.state.query && <EksamensPane queryString={this.state.query} /> */}
                    </div>
                    <div className={`${searchresultsClass}`}>
                        <InformationMessageBox
                            isDesktop={isDesktop}
                            currentPage="Global"
                            informationMessage={this.state.informationMessage}
                        />
                        {!isDesktop && <Resultnumber currentPageNumber={this.state.currentPageNumber} isDesktop={isDesktop} numberOfResults={numberOfResults} resultPerPage={this.resultPerPage} />}
                        {!isDesktop && this.state.filter && <FiltersSelected filters={this.state.filter} isDesktop={isDesktop} />}
                        {this.renderResultPane()}
                        {numberOfPages > 1 && <Pagination currentPage={this.state.currentPageNumber} numberOfPages={numberOfPages} onPageChanged={(newPageNumber) => this.onPageChanged(newPageNumber)} searchQuery={this.state.searchboxQuery} />}
                        <MoveToTop />
                    </div>
                </div>
            </div>
        );
    }

    private renderResultPane() {
        if (!this.state.results) return null
        if (this.state.results.items.length === 0 && this.state.results.elapsedTime !== null) {
            if (!this.state.query) return null;
            let currentFilters = getFilterParametersFromUrl(this.refinementFilters);
            return <NoResultPane onSearchClearFilters={(query) => this.onSearchClearFilters(query)} searchQuery={this.state.query} filters={getFilterNames(currentFilters ? currentFilters : [])} />
        }
        return <ResultPane results={this.state.results.items as IGlobalResult[]} queryText={this.state.results.queryText} />;
    }

    private getFilterDisplayName(filterName: string) {
        switch(filterName) {
            case 'innholdstypefilter':
                return 'Type innhold';
            case 'datoendret':
                return 'Tidspunkt';
            case 'fltypefilter':
                return 'Læreplan';
            case 'ExCatalogTypeName':
                return 'Eksamen';
            case 'rapportinnholdstype':
                return 'Forskning og statistikk';
            case 'GlobalsokType':
                return 'Type innhold';
            default:
                return filterName
        }
    }

    private async setupAndRunInitialQuery() {
        let pageNumber = this.state.initialPageNumber ? this.state.initialPageNumber : 1;
        let initialFilters: any[] | undefined = undefined;
        let initialQuery: string = this.state.initialQuery ? this.state.initialQuery : '';
        if(initialQuery !== '*') {
            setUrlParameter(document.location.href, this.queryParameter, initialQuery, true); //We do not set window.history on first load.
        }
        if (this.state.initialFilters && this.state.initialFilters.length > 0) {
            initialFilters = getFilterParametersFromUrl(this.refinementFilters);
        }
        this.setState({
            searchboxQuery: (document.getElementById('searchBoxInput') as HTMLInputElement).value,
            newStateQuery: undefined,
            currentPageNumber: pageNumber
        });
        await this.runQuery(initialQuery, false, pageNumber, initialFilters, true);
    }


    private onSearch(query: string, searchAsYouType: boolean) {
        let urlFilters = getFilterParametersFromUrl(this.refinementFilters);
        let searchboxQuery = (document.getElementById('searchBoxInput') as HTMLInputElement).value;
        this.setState({
            query: query,
            searchboxQuery: searchboxQuery
        });
        if(urlFilters && urlFilters.length > 0){
            this.runQuery(query, false, 1, urlFilters);
        }else{
            this.runQuery(query, false, 1);
        }

        //Only update when user have initiated a search from a action, not type as you search
        if(searchAsYouType === true) {
            setUrlParameter(window.location.href, this.queryParameter, query, true);
        } else {
            setUrlParameter(window.location.href, this.queryParameter, query);
        }

    }

    private onSearchClearFilters(query: string) {
        //Clear all the url-params
        this.refinementFilters.forEach(refinementFilter => {
            setUrlParameter(window.location.href, refinementFilter, '');
        });
        this.onSearch(query, false);
    }

    private onPageChanged(newPageNumber: number) {
        let filter = this.state.filter ? this.state.filter : undefined;
        if (this.state.query) {
            this.runQuery(this.state.query, false, newPageNumber, filter);
            setUrlParameter(window.location.href, 'page', newPageNumber);
            this.setState({
                currentPageNumber: newPageNumber
            });
            //Scroll to top
            window.scrollTo(0, 0);
        }
    }

    private remapFilters(fetchedRefiners: IRefiners[], initFilter: any[] | undefined, selectedFilter: string) {
        if (initFilter && initFilter.length > 0) {
            this.refinementFilters.forEach(filterName => {
                let currentInitFilter = initFilter.filter(val => val.Name === filterName)[0];

                if (currentInitFilter && fetchedRefiners) {
                    let currentFetchedFilter = fetchedRefiners.filter(val => val.Name === filterName)[0];
                    if (currentFetchedFilter) {
                        currentFetchedFilter.Entries.forEach(refiner => {
                            refiner.checked = currentInitFilter.Entries.filter(val => val.RefinementToken === refiner.RefinementToken)[0] !== undefined
                        });
                    }
                }
            });
        }
        return fetchedRefiners;
    }

    private async runQuery(query: string, isRerun: boolean, pageNumber?: number, filter?: IRefiners[], isInitialQuery?: boolean) {
        if(!filter){
            filter = this.state.filter;
        }
        let _pageNumber = pageNumber !== undefined ? pageNumber : 1;
        let results = await getResults(query, _pageNumber, filter);
        let resultsNoFilter;

        if(results.items.length === 0){
            //Run query without any filters
            resultsNoFilter = await getResults(query, _pageNumber, addNonActiveFilters([]));
        }


        if (results.spellingSuggestion !== undefined && results.spellingSuggestion !== query && results.spellingSuggestion.length > 0 && results.items.length === 0) {
            //Run again with querysuggestion

            this.setState({
                querySuggestion: results.spellingSuggestion,
                erronousQuery: query
            });
            this.runQuery(results.spellingSuggestion, true, pageNumber, filter);
            return;

        } else if(resultsNoFilter && resultsNoFilter.totalResults > 0) {
            this.setState({
                filter: resultsNoFilter.refiners,
                results: resultsNoFilter,
                query: query,
                isSearchingWithDeactivatedFilters: results.items.length > 0,
                searchWithoutFiltersHasResults: true,
                querySuggestion: undefined
            });

        } else {
            //Found results, setting all the proper variables.

            let setStateObject = {
                results: results,
                searchWithoutFiltersHasResults: false,
                currentPageNumber: pageNumber ? pageNumber : 1
            };
            if (isInitialQuery) {
                setStateObject['filter'] = this.remapFilters(results.refiners, this.state.initialFilters, "");
            } else {
                setStateObject['filter'] = this.remapFilters(results.refiners, getFilterParametersFromUrl(this.refinementFilters), "");
            }
            setStateObject['isSearchingWithDeactivatedFilters'] = false;
            setStateObject['query'] = query;
            if (!isRerun) {
                setStateObject['querySuggestion'] = undefined
            }
            setStateObject['currentPageNumber'] = _pageNumber;
            this.setState(setStateObject);
        }
    }


    private findNumberOfPages() {
        if (!this.state.results || !this.state.results.totalResults) {
            return 1;
        }
        return Math.ceil(this.findNumberOfResults() / this.resultPerPage);
    }

    private findNumberOfResults() {
        if (!this.state.results) {
            return 0;
        }
        return this.state.results.totalResults
    }



    private async onFilterChanged(refinerName: string, refinerIndex: number) {
        let currentFilter = this.state.filter;
        if (!currentFilter) {
            return
        }
        let newPreviousFilters = currentFilter.slice();
        let previousFilters = this.state.previousFilters;
        let currentFilterSelection = currentFilter.filter(filter => filter.Name === refinerName)[0].Entries[refinerIndex];
        const newCheckedValue = !currentFilterSelection.checked;

        currentFilterSelection.checked = newCheckedValue;

        let currentFilterUrlParam = getUrlParameter(refinerName);
        let newFilterParamValue;

        if(refinerName === 'publisertdato') {
            newFilterParamValue = `${currentFilterSelection.RefinementToken}`
        } else {
            newFilterParamValue = `${currentFilterSelection.RefinementValue}`;
        }
        let newFilterUrlParam = "";
        if (currentFilterSelection.checked) {
            if (currentFilterUrlParam) {
                newFilterUrlParam = `${currentFilterUrlParam};${newFilterParamValue}`
            } else {
                newFilterUrlParam = newFilterParamValue
            }
        } else {
            if (currentFilterUrlParam) {
                newFilterUrlParam = currentFilterUrlParam.split(';').filter(val => val !== newFilterParamValue).join('; ');
            }
        }
        setUrlParameter(window.location.href, refinerName, newFilterUrlParam);

        const query = this.state.query ? this.state.query : '';
        const newResults = await getResults(query, 1, currentFilter);
        const nextFilter = this.remapFilters(newResults.refiners, getFilterParametersFromUrl(this.refinementFilters), refinerName);

        let newPreviousFiltersToSave;
        if (!newCheckedValue) {
            if (previousFilters) {
                previousFilters = previousFilters.filter(filter => filter.Name !== refinerName);
                previousFilters.filter(filter => filter.Name !== refinerName).forEach(prevFilter => {
                    nextFilter.filter(NF => NF.Name === prevFilter.Name)[0].Entries = prevFilter.Entries;
                });
                newPreviousFiltersToSave = previousFilters;
            }
        } else {
            newPreviousFiltersToSave = newPreviousFilters;
        }
;
        //For selected filter - set nextFilter to currentFilter (no update/refresh for selected filter/refiner)
        let currentFilterSelectedNode = currentFilter.filter(val => val.Name === refinerName)[0];
        let ensuredNextFilter = ensureSelectedFilterValues(nextFilter, currentFilterSelectedNode, refinerName);


        this.setState({
            results: newResults,
            filter: ensuredNextFilter,
            currentPageNumber: 1,
            query: query,
            querySuggestion: undefined,
            previousFilters: newPreviousFiltersToSave
        });
    }

    private async emptyFilters() {
        Log("Clicked on 'Empty filters'");
        let refiners = this.state.filter
        Log(this.state.filter);
        if (refiners === undefined) {
            return 0
        }

        //Clear all the checkboxes
        refiners.forEach(refiner => {
            refiner.Entries.forEach(refinerValue => {
                refinerValue.checked = false
            });
        });

        removeAllFilterParams(window.location.href, this.refinementFilters);

        //Run the query without filters
        const query = this.state.query ? this.state.query : "*";

        this.runQuery(query, false);
    }

    private async onAllFilterClicked(refinerName: string) {
        let stateRefiners = this.state.filter;
        
        if (!stateRefiners) return;
        
        let allFilterClickedRefiner = stateRefiners.filter(refiner => refiner.Name === refinerName)[0];
        if (allFilterClickedRefiner !== undefined) {
            const somethingIsChecked = allFilterClickedRefiner.Entries.filter(entry => entry.checked === true).length > 0;
            if (somethingIsChecked) {
                allFilterClickedRefiner.Entries.forEach(refinerValue => {
                    refinerValue.checked = false;
                });
                setUrlParameter(window.location.href, refinerName, '');
                const query = this.state.query ? this.state.query : '';
                let urlFilters = getFilterParametersFromUrl(this.refinementFilters);
                this.runQuery(query, false, 1, urlFilters);
            }
        }
    }

    private onStatePopped() {
        const newStateQuery = getUrlParameter(this.queryParameter);
        const initialPageNumber = getUrlParameter('page');
        const safeInitialPageNumber = initialPageNumber ? +initialPageNumber : 1;
        const filterParameters = getFilterParametersFromUrl(this.refinementFilters);

        // Update search box query
        (document.getElementById('searchBoxInput') as HTMLInputElement).value = newStateQuery===undefined ? "" : newStateQuery;

        //Set up variables properly (like in the constructor), and run the setupjob
        this.setState({
            newStateQuery: newStateQuery,
            initialQuery: newStateQuery,
            initialPageNumber: safeInitialPageNumber,
            initialFilters: filterParameters,
        }, () => {
            this.setupAndRunInitialQuery();
        });
    }

    private _onWindowResize() {
        let currentDisplayFormat = getCurrentDisplayFormat();
        this.setState({ displayFormat: currentDisplayFormat });
    }

}
