import React, { FunctionComponent } from 'react';

const UdirLogo : FunctionComponent = () => {
    return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 1737 500">
        <g strokeWidth="0">
            <g fill="#272727">
                <path d="M645.3,418.6h-16l-0.8-18.9c-4.2,7.1-9.7,12.3-16.6,15.7c-6.9,3.4-14.2,5.1-21.8,5.1
                    c-7.3,0-14.2-1.2-20.5-3.5c-6.3-2.4-11.8-5.8-16.5-10.4c-4.7-4.6-8.4-10.2-11-16.7c-2.6-6.6-4-14.1-4-22.6c0-8.6,1.3-16.2,4-22.9
                    c2.6-6.7,6.3-12.2,11-16.8c4.7-4.5,10.2-7.9,16.4-10.2c6.2-2.3,13-3.4,20.4-3.4c3.6,0,7.3,0.4,11.1,1.2c3.8,0.8,7.4,2.1,10.8,3.9
                    c3.4,1.7,6.6,3.9,9.5,6.4s5.3,5.6,7.1,9v-55.9l17.1-6.2V418.6z M577.1,332.1c-4.4,1.6-8.2,4-11.5,7.2c-3.3,3.2-5.9,7.1-7.8,11.8
                    c-1.9,4.6-2.8,10-2.8,15.9c0,6,0.9,11.2,2.8,15.8c1.9,4.6,4.5,8.5,7.8,11.6c3.3,3.2,7.2,5.6,11.5,7.3c4.4,1.7,9,2.5,14,2.5
                    c5.1,0,9.9-0.9,14.4-2.7c4.4-1.8,8.3-4.3,11.5-7.6c3.3-3.3,5.8-7.2,7.7-11.8c1.9-4.6,2.8-9.7,2.8-15.4c0-5.5-0.9-10.6-2.8-15.1
                    c-1.9-4.5-4.5-8.4-7.8-11.7c-3.3-3.3-7.2-5.8-11.7-7.6s-9.2-2.7-14.4-2.7h0.2C586.2,329.7,581.5,330.5,577.1,332.1z"/>
                <path d="M678.5,281c2.4-1.9,4.9-2.8,7.7-2.8c2.8,0,5.3,0.9,7.7,2.8c2.4,1.9,3.5,4.7,3.5,8.4c0,3.7-1.2,6.5-3.5,8.3
                    c-2.4,1.8-4.9,2.7-7.7,2.7c-2.8,0-5.3-0.9-7.7-2.8s-3.5-4.7-3.5-8.4C675,285.6,676.2,282.9,678.5,281z M694.6,315.8v102.8h-17.1
                    V315.8H694.6z"/>
                <path d="M744.1,331c3.6-6.7,8.3-11.2,14-13.5c5.8-2.4,11.6-3.5,17.6-3.5c4.4-0.1,8.7,0.5,12.9,2
                    c4.2,1.5,7.8,3.4,11,5.7l-7.7,14.6c-2.8-1.9-5.5-3.4-8.3-4.3c-2.8-0.9-6-1.4-9.6-1.4c-4.2,0-8,0.7-11.7,2.2
                    c-3.6,1.5-6.8,3.5-9.6,6.1c-2.8,2.6-5,5.9-6.6,9.7c-1.6,3.8-2.4,8.1-2.4,12.8v57.2h-17.1V315.8l16,0L744.1,331z"/>
                <path d="M883.9,416c-8.1,3-16.2,4.5-24.2,4.5c-7.9,0-15.2-1.2-21.8-3.7c-6.7-2.5-12.4-6.1-17.2-10.8
                    c-4.8-4.7-8.5-10.4-11.1-17c-2.6-6.6-4-14-4-22.2c0-7.8,1.3-14.9,4-21.4c2.6-6.5,6.3-12.1,11-16.9c4.7-4.7,10.3-8.4,16.9-11
                    c6.5-2.6,13.7-4,21.4-4c7.9,0,15.2,1.2,21.8,3.6c6.7,2.4,12.3,6.1,17.1,11.1c4.7,5,8.2,11.3,10.5,18.8c2.3,7.6,2.9,16.5,2,26.7
                    h-87.4c0.4,4.7,1.7,9,3.7,12.8c2.1,3.8,4.8,7,8.1,9.7c3.3,2.6,7.1,4.7,11.4,6.1c4.3,1.5,8.9,2.2,13.9,2.2c2.9,0,5.9-0.3,9.1-0.8
                    c3.1-0.6,6.1-1.4,9.1-2.5c2.9-1.1,5.7-2.5,8.2-4.1c2.6-1.6,4.7-3.4,6.3-5.5l11.3,10.3l-0.1,0.1C898.7,408.4,892.1,413,883.9,416z
                        M883.9,336.8c-6.3-5.3-14.5-7.9-24.7-7.9c-9,0-17,2.6-23.8,7.8c-6.9,5.2-11.1,12.8-12.6,22.8H894v0.2
                    C893.6,349.7,890.2,342.1,883.9,336.8z"/>
                <path d="M994.1,315.8h21.2v1l-47.4,47.4l53.3,53.1v1.2h-20.8l-48.5-48.5v48.5H935v-140l16.9-6.1v86.6L994.1,315.8z"
                    />
                <path d="M1100,315.8v14.6h-32v54.5c0,6.1,1.1,10.9,3.4,14.5c2.3,3.5,6.6,5.3,12.8,5.3c2.4,0,4.8-0.3,7.2-1
                    c2.4-0.7,4.8-1.6,7.2-2.7l5,14.1c-3.5,1.5-6.8,2.7-10,3.5c-3.2,0.8-6.5,1.3-10,1.5c-11.1,0.3-19.3-2.7-24.5-8.8
                    c-5.3-6.2-7.9-14.9-7.9-26.3v-54.5h-20.6v-14.6h20.6v-27.6l17.1-6.2v33.8H1100z"/>
                <path d="M1120.3,345.5c2.7-6.7,6.4-12.3,11.1-17.1c4.7-4.7,10.3-8.4,16.9-11c6.5-2.6,13.6-4,21.2-4
                    c7.9,0,15.1,1.3,21.6,4c6.5,2.6,12.1,6.3,16.9,11c4.7,4.7,8.4,10.4,11,17.1c2.6,6.7,4,13.9,4,21.8c0,7.8-1.3,14.9-4,21.4
                    c-2.6,6.5-6.3,12.1-11.1,16.8c-4.8,4.7-10.4,8.4-17,10.9c-6.5,2.6-13.7,3.8-21.4,3.8c-7.8,0-14.9-1.3-21.3-3.8
                    c-6.4-2.6-12.1-6.2-16.9-10.9c-4.8-4.7-8.5-10.3-11.1-16.8c-2.6-6.5-4-13.7-4-21.4C1116.2,359.5,1117.6,352.2,1120.3,345.5z
                        M1203.3,351.8c-1.9-4.7-4.4-8.7-7.7-12.1c-3.3-3.3-7.1-5.9-11.5-7.8c-4.4-1.9-9.2-2.8-14.4-2.8c-5.3,0-10.1,0.9-14.6,2.8
                    c-4.4,1.9-8.3,4.5-11.5,7.8c-3.3,3.3-5.8,7.4-7.6,12.1c-1.8,4.7-2.7,9.9-2.7,15.6c0,5.4,0.9,10.4,2.7,14.9
                    c1.8,4.5,4.3,8.4,7.5,11.8c3.2,3.3,7,5.9,11.4,7.7c4.4,1.8,9.3,2.7,14.6,2.7s10.2-0.9,14.7-2.7c4.5-1.8,8.4-4.4,11.5-7.7
                    c3.2-3.3,5.7-7.2,7.6-11.8c1.9-4.5,2.8-9.5,2.8-14.9C1206.1,361.7,1205.2,356.5,1203.3,351.8z"/>
                <path d="M1265.8,331c3.6-6.7,8.3-11.2,14-13.5c5.8-2.4,11.6-3.5,17.6-3.5c4.4-0.1,8.7,0.5,12.9,2
                    c4.2,1.5,7.8,3.4,11,5.7l-7.7,14.6c-2.8-1.9-5.5-3.4-8.3-4.3s-6-1.4-9.6-1.4c-4.2,0-8,0.7-11.6,2.2c-3.6,1.5-6.8,3.5-9.6,6.1
                    c-2.8,2.6-5,5.9-6.6,9.7c-1.6,3.8-2.4,8.1-2.4,12.8v57.2h-17.1V315.8l16,0L1265.8,331z"/>
                <path d="M1417.8,400.2c-2.1,3.6-4.5,6.7-7.4,9.3c-2.8,2.6-5.9,4.7-9.3,6.4c-3.3,1.7-6.8,3-10.5,3.8
                    c-3.7,0.8-7.2,1.2-10.7,1.2c-7.3,0-14.3-1.2-20.7-3.7c-6.4-2.5-12-6.1-16.7-10.7c-4.7-4.6-8.4-10.3-11.1-17s-4.1-14.1-4.1-22.5
                    c0-8.3,1.4-15.8,4.2-22.4c2.8-6.6,6.6-12.2,11.3-16.8c4.8-4.6,10.4-8,16.7-10.4c6.4-2.4,13.2-3.5,20.6-3.5
                    c7.8,0,15.1,1.7,22.1,5.2c6.9,3.5,12.3,8.5,16,15.2l0.2-18.7h16.6v102.8l-16.9,0L1417.8,400.2z M1366.4,332.9
                    c-4.4,1.7-8.3,4.2-11.7,7.5c-3.3,3.3-6,7.2-7.9,11.9s-2.9,9.8-2.9,15.5c0,5.7,1,10.9,2.9,15.5c1.9,4.6,4.6,8.6,7.9,11.9
                    c3.3,3.3,7.2,5.8,11.7,7.6c4.4,1.8,9.2,2.7,14.4,2.7c8.2,0,15-1.9,20.6-5.6c5.5-3.7,9.7-8.4,12.5-13.9c2.8-5.5,4.2-11.6,4.2-18.1
                    c0-6.5-1.4-12.5-4.2-18.1c-2.8-5.6-6.9-10.2-12.4-13.9c-5.5-3.7-12.4-5.6-20.7-5.4C1375.7,330.3,1370.9,331.2,1366.4,332.9z"/>
                <path d="M1528.1,315.8v14.6h-32v54.5c0,6.1,1.1,10.9,3.4,14.5c2.3,3.5,6.6,5.3,12.8,5.3c2.4,0,4.7-0.3,7.2-1
                    c2.4-0.7,4.8-1.6,7.2-2.7l5,14.1c-3.5,1.5-6.8,2.7-10,3.5c-3.2,0.8-6.5,1.3-10,1.5c-11.1,0.3-19.3-2.7-24.6-8.8
                    c-5.3-6.2-7.9-14.9-7.9-26.3v-54.5h-20.6v-14.6h20.6v-27.6l17.1-6.2v33.8H1528.1z"/>
                <path d="M1622.9,416c-8.1,3-16.2,4.5-24.2,4.5c-7.9,0-15.2-1.2-21.8-3.7c-6.7-2.5-12.4-6.1-17.2-10.8
                    c-4.8-4.7-8.5-10.4-11.1-17c-2.6-6.6-4-14-4-22.2c0-7.8,1.3-14.9,4-21.4c2.6-6.5,6.3-12.1,11-16.9c4.7-4.7,10.3-8.4,16.8-11
                    c6.5-2.6,13.7-4,21.4-4c7.9,0,15.2,1.2,21.8,3.6c6.7,2.4,12.3,6.1,17.1,11.1c4.7,5,8.2,11.3,10.5,18.8c2.3,7.6,2.9,16.5,2,26.7
                    h-87.4c0.4,4.7,1.7,9,3.7,12.8c2.1,3.8,4.8,7,8.1,9.7c3.3,2.6,7.1,4.7,11.4,6.1c4.3,1.5,8.9,2.2,13.9,2.2c2.9,0,5.9-0.3,9-0.8
                    c3.1-0.6,6.1-1.4,9-2.5c2.9-1.1,5.7-2.5,8.2-4.1c2.6-1.6,4.7-3.4,6.3-5.5L1643,402l-0.1,0.1C1637.7,408.4,1631,413,1622.9,416z
                        M1622.9,336.8c-6.3-5.3-14.5-7.9-24.7-7.9c-9,0-17,2.6-23.8,7.8c-6.9,5.2-11.1,12.8-12.6,22.8h71.2v0.2
                    C1632.5,349.7,1629.2,342.1,1622.9,336.8z"/>
                <path d="M1733.9,315.8v14.6h-32v54.5c0,6.1,1.1,10.9,3.4,14.5c2.3,3.5,6.6,5.3,12.8,5.3c2.4,0,4.7-0.3,7.2-1
                    c2.4-0.7,4.8-1.6,7.2-2.7l5,14.1c-3.5,1.5-6.8,2.7-10,3.5c-3.2,0.8-6.5,1.3-10,1.5c-11.1,0.3-19.3-2.7-24.6-8.8
                    c-5.3-6.2-7.9-14.9-7.9-26.3v-54.5h-20.6v-14.6h20.6v-27.6l17.1-6.2v33.8H1733.9z"/>
                <path d="M665,139.1c0,10.1-1.6,19-4.8,26.5c-3.2,7.6-7.5,13.9-12.9,18.9c-5.4,5.1-11.7,8.9-18.8,11.4
                    c-7.1,2.6-14.7,3.8-22.6,3.8c-7.8,0-15.3-1.2-22.5-3.7c-7.2-2.5-13.6-6.3-19-11.3c-5.5-5.1-9.9-11.4-13.2-18.9
                    c-3.3-7.6-5-16.5-5-26.7V57.7l17.9-6.5v87.9c0,7.2,1.1,13.6,3.3,19c2.2,5.5,5.3,10.1,9.2,13.8c3.9,3.7,8.3,6.6,13.3,8.4
                    c5,1.9,10.3,2.8,16,2.8c5.5,0,10.8-1,15.8-2.9c5-1.9,9.4-4.8,13.1-8.5c3.7-3.7,6.7-8.4,8.9-13.9c2.2-5.5,3.3-11.9,3.3-18.9V57.5
                    L665,51V139.1z"/>
                <path d="M757,94.1v14.6h-32v54.7c0,6.1,1.1,10.9,3.4,14.5c2.3,3.5,6.6,5.3,12.8,5.3c2.4,0,4.8-0.3,7.2-1
                    c2.4-0.7,4.8-1.6,7.2-2.7l5,14.1c-3.5,1.5-6.8,2.7-10,3.5c-3.2,0.8-6.5,1.3-10,1.5c-11.1,0.3-19.3-2.7-24.5-8.8
                    c-5.3-6.2-7.9-14.9-7.9-26.3v-54.7h-20.6V94.1h20.6V66.7l17.1-6.2v33.6H757z"/>
                <path d="M877.9,197.1h-16l-0.8-18.9c-4.2,7.1-9.7,12.3-16.6,15.7c-6.9,3.4-14.2,5.1-21.8,5.1
                    c-7.3,0-14.2-1.2-20.5-3.5c-6.3-2.4-11.8-5.8-16.5-10.4c-4.7-4.6-8.4-10.2-11-16.7c-2.6-6.6-4-14.1-4-22.6c0-8.6,1.3-16.2,4-22.9
                    c2.6-6.7,6.3-12.2,11-16.8c4.7-4.5,10.2-7.9,16.4-10.2c6.2-2.3,13-3.4,20.4-3.4c3.6,0,7.3,0.4,11.1,1.2c3.8,0.8,7.4,2.1,10.8,3.9
                    s6.6,3.9,9.5,6.4c2.9,2.6,5.3,5.6,7.1,9V57.2l17.1-6.2V197.1z M809.7,110.7c-4.4,1.6-8.2,4-11.5,7.2c-3.3,3.2-5.9,7.1-7.8,11.8
                    c-1.9,4.6-2.8,10-2.8,15.9c0,6,0.9,11.2,2.8,15.8c1.9,4.6,4.5,8.5,7.8,11.6c3.3,3.2,7.2,5.6,11.5,7.3c4.4,1.7,9,2.5,14,2.5
                    c5.1,0,9.9-0.9,14.4-2.7c4.4-1.8,8.3-4.3,11.5-7.6c3.3-3.3,5.8-7.2,7.7-11.8c1.9-4.6,2.8-9.7,2.8-15.4c0-5.5-0.9-10.6-2.8-15.1
                    c-1.9-4.5-4.5-8.4-7.8-11.7c-3.3-3.3-7.2-5.8-11.7-7.6s-9.2-2.7-14.4-2.7h0.2C818.8,108.3,814.1,109.1,809.7,110.7z"/>
                <path d="M991,178.8c-2.1,3.6-4.5,6.7-7.4,9.3c-2.8,2.6-5.9,4.7-9.3,6.4c-3.3,1.7-6.8,3-10.5,3.8
                    c-3.7,0.8-7.2,1.2-10.7,1.2c-7.4,0-14.3-1.2-20.7-3.7c-6.4-2.5-12-6.1-16.7-10.7c-4.7-4.6-8.4-10.3-11.1-17s-4.1-14.1-4.1-22.5
                    c0-8.3,1.4-15.8,4.2-22.4c2.8-6.6,6.6-12.2,11.3-16.8c4.8-4.6,10.4-8,16.7-10.4c6.4-2.4,13.2-3.5,20.6-3.5
                    c7.8,0,15.1,1.7,22.1,5.2c6.9,3.5,12.3,8.5,16,15.2l0.2-18.7h16.6v102.8h-16.9L991,178.8z M939.6,111.5
                    c-4.4,1.7-8.3,4.2-11.7,7.5s-6,7.2-7.9,11.9c-1.9,4.6-2.9,9.8-2.9,15.5c0,5.7,1,10.9,2.9,15.5c1.9,4.6,4.6,8.6,7.9,11.9
                    c3.3,3.3,7.2,5.8,11.7,7.6c4.4,1.8,9.2,2.7,14.4,2.7c8.2,0,15-1.9,20.6-5.6c5.5-3.7,9.7-8.4,12.5-13.9c2.8-5.5,4.2-11.6,4.2-18.1
                    c0-6.5-1.4-12.5-4.2-18.1c-2.8-5.6-6.9-10.2-12.4-13.9c-5.5-3.7-12.4-5.6-20.7-5.4C948.9,108.9,944.1,109.8,939.6,111.5z"/>
                <path d="M1053,94.1l0.8,16.4c4.4-6.2,9.7-10.8,15.7-13.6c6-2.8,12.5-4.3,19.5-4.3c6.5,0,12.5,1.1,18,3.2
                    c5.5,2.1,10.2,5.2,14.3,9.3c4,4,7.2,9,9.5,15c2.3,6,3.4,12.7,3.4,20.2v56.8h-17.1v-56.6c0-10-2.7-17.8-8.2-23.5
                    c-5.5-5.7-13.1-8.5-23-8.5c-4.7,0-9,0.9-12.9,2.7c-3.9,1.8-7.2,4.2-10,7.2c-2.8,3-4.9,6.5-6.4,10.6c-1.5,4.1-2.3,8.4-2.3,13v55.1
                    h-16.9V94.3v-0.2H1053z"/>
                <path d="M1177.4,94.1l0.8,16.4c4.4-6.2,9.7-10.8,15.7-13.6c6-2.8,12.5-4.3,19.5-4.3c6.5,0,12.5,1.1,18,3.2
                    c5.5,2.1,10.2,5.2,14.3,9.3s7.2,9,9.5,15c2.3,6,3.4,12.7,3.4,20.2v56.8h-17.1v-56.6c0-10-2.7-17.8-8.2-23.5
                    c-5.5-5.7-13.1-8.5-23-8.5c-4.7,0-9,0.9-12.9,2.7c-3.9,1.8-7.2,4.2-10,7.2c-2.8,3-4.9,6.5-6.4,10.6c-1.5,4.1-2.3,8.4-2.3,13v55.1
                    h-16.9V94.3v-0.2H1177.4z"/>
                <path d="M1287.3,59.5c2.4-1.9,4.9-2.8,7.7-2.8c2.8,0,5.3,0.9,7.7,2.8c2.4,1.9,3.5,4.7,3.5,8.4c0,3.7-1.2,6.5-3.5,8.3
                    c-2.4,1.8-4.9,2.7-7.7,2.7c-2.8,0-5.3-0.9-7.7-2.8c-2.4-1.9-3.5-4.7-3.5-8.4C1283.8,64.2,1284.9,61.4,1287.3,59.5z M1303.3,94.1
                    v103h-17.1v-103H1303.3z"/>
                <path d="M1348,94.1l0.8,16.4c4.4-6.2,9.7-10.8,15.7-13.6c6-2.8,12.5-4.3,19.5-4.3c6.5,0,12.5,1.1,18,3.2
                    c5.5,2.1,10.2,5.2,14.3,9.3s7.2,9,9.5,15c2.3,6,3.4,12.7,3.4,20.2v56.8h-17.1v-56.6c0-10-2.7-17.8-8.2-23.5
                    c-5.5-5.7-13.1-8.5-23-8.5c-4.7,0-9,0.9-12.9,2.7c-3.9,1.8-7.2,4.2-10,7.2c-2.8,3-4.9,6.5-6.4,10.6c-1.5,4.1-2.3,8.4-2.3,13v55.1
                    h-16.9V94.3v-0.2H1348z"/>
                <path d="M1471.3,227.2c2,3.5,4.7,6.4,8.1,8.7c3.4,2.3,7.3,4,11.6,5.1c4.4,1.1,8.9,1.7,13.6,1.7
                    c4.4,0,8.8-0.4,13.1-1.2c4.3-0.8,8.2-2.2,11.6-4.2c3.5-1.9,6.2-4.5,8.3-7.6c2.1-3.1,3.1-6.9,3.1-11.3c0-4.2-1-7.8-2.9-10.9
                    c-1.9-3.1-4.6-5.7-7.9-7.7c-3.3-2-7.2-3.5-11.5-4.6c-4.4-1-8.9-1.6-13.6-1.6c-8,0-15.4-1.1-22.1-3.4c-6.7-2.3-12.4-5.6-17.2-10
                    c-4.8-4.4-8.5-9.7-11.1-15.9c-2.6-6.2-4-13.4-4-21.4c0-8.5,1.6-15.9,4.8-22.3c3.2-6.4,7.4-11.7,12.5-15.9
                    c5.1-4.2,10.9-7.4,17.4-9.6s13-3.2,19.7-3.2c4.7,0,9.8,0.6,15.3,1.9c5.5,1.3,10.1,3.2,13.8,5.8l11-13.3l12.1,9.6l-11.6,13.7
                    c4,4.6,7.2,9.4,9.7,14.5c2.4,5.1,3.6,11.3,3.6,18.8c0,4.4-0.6,8.8-1.7,13c-1.1,4.2-2.9,8.2-5.3,12c-2.4,3.7-5.4,7.1-9.1,10
                    c-3.6,2.9-7.9,5.2-12.9,6.9c10,2.9,17.2,7.4,21.6,13.3c4.4,6,6.7,12.7,6.7,20.2c0,6.7-1.5,12.5-4.6,17.5
                    c-3.1,5-7.1,9.2-12.1,12.6c-5,3.4-10.7,5.9-17.2,7.6c-6.4,1.7-13.1,2.5-19.9,2.5c-7.8,0-14.9-1-21.4-3c-6.5-2-12.1-4.9-16.7-8.6
                    c-4.6-3.7-8.3-8.3-10.8-13.7c-2.6-5.4-3.8-11.6-3.6-18.5h16.4C1468.3,219.4,1469.3,223.6,1471.3,227.2z M1470.7,158.3
                    c2,4.5,4.7,8.3,8.1,11.2c3.4,3,7.3,5.2,11.9,6.8c4.5,1.5,9.3,2.3,14.5,2.3c5.4,0,10.4-0.9,15-2.8c4.6-1.9,8.5-4.4,11.8-7.6
                    c3.3-3.2,5.8-6.9,7.6-11.2c1.8-4.3,2.7-8.9,2.7-13.9c0-5.8-1-11-3.1-15.4c-2.1-4.4-4.8-8.2-8.2-11.1c-3.4-3-7.3-5.2-11.9-6.8
                    s-9.2-2.3-14-2.3c-4.6,0-9.1,0.7-13.6,2.2c-4.5,1.5-8.5,3.6-12.1,6.6c-3.5,2.9-6.4,6.6-8.5,11c-2.1,4.4-3.2,9.6-3.2,15.6
                    C1467.6,148.6,1468.7,153.8,1470.7,158.3z"/>
                <path d="M1634,109.2c-4.8-1.3-9.8-2-15.1-2c-3.6,0-6.9,0.3-10,0.8c-3,0.6-5.7,1.5-8,2.7c-2.3,1.2-4.1,2.8-5.4,4.8
                    c-1.3,1.9-2,4.2-2,6.7c0,2.6,0.7,4.9,2.2,6.7c1.5,1.8,3.4,3.3,5.8,4.4c2.4,1.1,5.2,2,8.2,2.6c3,0.6,6.2,1.1,9.4,1.6
                    c4.7,0.6,9.6,1.3,14.8,2.3c5.1,1,9.8,2.6,14,4.8c4.2,2.2,7.7,5.2,10.5,9c2.8,3.8,4.1,8.9,4,15.3c-0.1,5.1-1.4,9.6-3.6,13.5
                    c-2.3,3.9-5.4,7.1-9.4,9.7c-4,2.6-8.5,4.5-13.6,5.7c-5.1,1.2-10.6,1.9-16.4,1.9c-8.6,0-17-1.4-25.1-4.2s-15.1-7.8-20.9-15.2
                    l9.8-11.9c4.6,5.7,10.2,9.7,17,12.2c6.7,2.4,13.3,3.6,19.7,3.6c2.8,0,5.7-0.2,8.7-0.7c3.1-0.5,5.8-1.4,8.3-2.6
                    c2.5-1.3,4.6-2.9,6.2-4.9c1.7-2,2.6-4.6,2.7-7.8c0-2.8-0.7-5.1-2.1-7c-1.4-1.9-3.3-3.4-5.8-4.7c-2.5-1.2-5.4-2.3-8.8-3
                    c-3.4-0.8-7-1.4-10.9-1.8c-5-0.6-9.9-1.4-14.8-2.5c-4.9-1.1-9.2-2.8-13.1-5c-3.9-2.2-7-5.1-9.5-8.6c-2.4-3.5-3.6-8-3.6-13.4
                    s1.2-10.1,3.6-13.9c2.4-3.9,5.7-7,9.7-9.5c4-2.4,8.5-4.2,13.4-5.2c4.9-1,9.9-1.6,14.9-1.6c7.8,0,14.8,1,21.2,3.1
                    c6.4,2.1,12.3,5.6,17.9,10.6L1648,117l-0.2,0.2C1643.4,113.2,1638.8,110.5,1634,109.2z"/>
                <path d="M1733.9,149.9h-51.6v-16.2h51.6V149.9z"/>
            </g>
            <path   fill="#7DBF9D" d="M420.4,0v310.4c0,74.5-60.4,134.8-134.8,134.8c-13.3,0-26.1-1.9-38.2-5.5c14.1-21.4,22.4-47.1,22.4-74.6V54.8
                L420.4,0z"/>
            <path  fill="#303030" d="M247.3,439.7c-24.2,36.4-65.6,60.3-112.5,60.3C60.4,500,0,439.6,0,365.2V75.8L150.7,21v289.3
                C150.7,371.5,191.4,423.2,247.3,439.7z"/>
        </g>
    </svg>
    );
}


export default UdirLogo;
