import React, { FunctionComponent } from 'react';


export interface IResultnumberProps {
    isDesktop: boolean;
    resultPerPage: number;
    numberOfResults: number;
    currentPageNumber: number;
}



export const Resultnumber: FunctionComponent<IResultnumberProps> = ({isDesktop, resultPerPage, numberOfResults, currentPageNumber})  => {
    const numberOfResultsString = findNumberOfResultString(numberOfResults, resultPerPage, currentPageNumber);

    if(numberOfResults === 0) {
        return <div></div>
    }

    return <span className={isDesktop ? "resultNumber" : "resultNumberMobile"} role="status">{numberOfResultsString}</span>
}


function findNumberOfResultString(numberOfResults: number, resultsPerPage: number, currentPageNumber: number) {
    if (numberOfResults < resultsPerPage) {
        return `Viser 1-${numberOfResults} av ${numberOfResults} treff`;
    } else {
        let topResultNumber = resultsPerPage * currentPageNumber;
        const bottomResultNumber = topResultNumber - (resultsPerPage - 1);
        if(numberOfResults < topResultNumber){
            topResultNumber = numberOfResults;
        }
        return `Viser ${bottomResultNumber}-${topResultNumber} av ${numberOfResults} treff`;
    }
}
