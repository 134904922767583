export function Log(obj: any) {
    if (window.location.href.indexOf("debug=true") !== -1 || window.location.hostname === "dev-sokeresultat.udir.no" ||window.location.hostname === "qa-sokeresultat.udir.no" || window.location.hostname === "udir-sok-test.azurewebsites.net" || window.location.hostname === "udir-sok-utv.azurewebsites.net" || window.location.hostname === "localhost")
    {
        console.log(obj);
    }
}
export function LogWarning(obj: any) {
    if (window.location.href.indexOf("debug=true") !== -1 || window.location.hostname === "dev-sokeresultat.udir.no" || window.location.hostname === "qa-sokeresultat.udir.no" || window.location.hostname === "udir-sok-test.azurewebsites.net" || window.location.hostname === "udir-sok-utv.azurewebsites.net" || window.location.hostname === "localhost")
    {
        console.warn(obj);
    }
}