import { ConvertStringToSearchToken, ConvertDateStringInUrlToSearchToken } from ".";
import { Log } from './logging';

export function getUrlParameter(name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(document.location.search);
    return results === null ? undefined : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export function setUrlParameter(url, _key, _value, skipBrowserHistoryUpdate?: boolean) {
    let key = encodeURIComponent(_key),
        value = encodeURIComponent(_value);
    let baseUrl = window.location.href.split('?')[0],
        newParam = key + '=' + value,
        params = '?' + newParam;
    let urlQueryString;
    if (url.split('?')[1] === undefined) { // if there are no query strings, make urlQueryString empty
        urlQueryString = '';
    } else {
        urlQueryString = '?' + url.split('?')[1];
    }


    // If the "search" string exists, then build params from it
    if (urlQueryString) {
        let updateRegex = new RegExp('([?&])' + key + '[^&]*');
        let removeRegex = new RegExp('([?&])' + key + '=[^&;]+[&;]?');
        if(_key !== 'page') {
            let removePageRegex = new RegExp('([?&])page=[^&;]+[&;]?');
            urlQueryString = urlQueryString.replace(removePageRegex, "");
        }
        if (typeof value === 'undefined' || value === null || value === "") {
            params = urlQueryString.replace(removeRegex, "$1");
            params = params.replace(/[&;]$/, "");
        } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
            params = urlQueryString.replace(updateRegex, "$1" + newParam);
        } else if (urlQueryString === "") { // If there are no query strings
            params = '?' + newParam;
        } else { // Otherwise, add it to end of query string
            params = urlQueryString + '&' + newParam;
        }
    }



    // no parameter was set so we don't need the question mark
    params = params === '?' ? '' : params;

    if(!skipBrowserHistoryUpdate) {
        window.history.pushState("SearchQuery", document.title, baseUrl + params);
    }
}

export function removeAllFilterParams(url: string, keys: string[]) {
    let baseUrl = window.location.href;
    keys.forEach(key => {
        let removeRegex = new RegExp('([?&])' + key + '=[^&;]+[^&;]?');
        baseUrl = baseUrl.replace(removeRegex, "");
    });

    let removePageRegex = new RegExp('([?&])page=[^&;]+[&;]?');
    baseUrl = baseUrl.replace(removePageRegex, "");

    let queryStartIndex = baseUrl.indexOf('&');
    let queryRegex = new RegExp('([?&])[a-z]*');
    let queryMatches = baseUrl.match(queryRegex);
    let queryCount = queryMatches ? queryMatches.length : 0;
    // If there exists more query paramaters
    if(queryStartIndex !== -1 && queryCount === 1) {
        baseUrl = baseUrl.substr(0, queryStartIndex) + '?' + baseUrl.substr(queryStartIndex + 1);
    }
    // Ensure & is replaced with ? in url (if not present)
    if(baseUrl.indexOf('&')!==-1 && baseUrl.indexOf('?')===-1){
        Log("Replace & with ? in baseUrl:" + baseUrl);
        baseUrl = baseUrl.replace('&', '?');
    }

    window.history.pushState("SearchQuery", document.title, baseUrl);
}

export function removeHashFromUrl(url: string){
    let urlWithoutHash = url.replace('html#?', 'html?'); 
    window.history.replaceState("SearchQuery", document.title, urlWithoutHash);
}

export function getFilterParametersFromUrl(filterList: string[]) {
    let initFilter: any[] = [];
    filterList.forEach(filter => {
        let filterValues = getUrlParameter(filter);
        if (filterValues) {
            let filterObjects = filterValues.split(';').map(filterValue => {
                let value = decodeURIComponent(filterValue);
                let token;
                if(refinerIsDate(value)) {
                    token = value;
                }else if(refinerIsDateInUrl(value)) {
                    token = ConvertDateStringInUrlToSearchToken(value);
                } else {
                    token = ConvertStringToSearchToken(value);
                }

                return {
                    RefinementName: value,
                    RefinementValue: value,
                    RefinementToken: token,
                    checked: true
                }
            })
            initFilter.push({
                'Name': filter,
                'Entries': filterObjects
            });
        }
    });
    Log(initFilter);
    return initFilter;
}

function refinerIsDate(testValue: string) {
    return testValue.slice(0,6) === 'range(';
}
function refinerIsDateInUrl(testValue: string) {
    if(testValue.startsWith("Before ")){
        return true;
    }else if(testValue.startsWith("From ") && testValue.indexOf("up to") !== -1){
        return true;
    }else if(testValue.endsWith(" or later")){
        return true;
    }
    else{
        return false;
    }
}