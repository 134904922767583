import React, { FC } from 'react';
import './NoResultPane.scss';
import { INoResultPaneProps } from './INoResultPaneProps';
import { Log } from '../../../utils/logging';

const NoResultPane: FC<INoResultPaneProps> = (props) => {
    Log("NoResultPane");
    Log(props);
    Log(props.filters);
    
    return (
        <div className="NoResultPane" role="status">
            <h2>Søket ditt ga 0 treff</h2>
            <p className='noresultText'>Ingen treff samsvarte med søket ditt på {renderSearchLink(props)} {generateFilterText(props.filters)}</p>
            <p className='noresultSuggestionsHeading'>Forslag:</p>
            <ul className='noresultSuggestionsList'>
                <li>Sjekk at alle ordene er stavet riktig</li>
                <li>Prøv andre søkeord</li>
                <li>Prøv mer generelle søkeord</li>
                {props.filters.length > 0 && (
                        <li>Søk på <a className="resultLink underline" href={`?k=${props.searchQuery}`}>{props.searchQuery}</a> uten filtervalg</li>
                    )
                }
            </ul>
        </div>
    );
}

const renderSearchLink = (props) => {
    //<span className="noResultsQueryLink">
    //  <button onClick={() => {props.onSearchClearFilters(props.searchQuery)}}>{props.searchQuery}</button>
    //</span>
    return (
        <span className="highlighted">
            {props.searchQuery}
        </span>
    );
}

const generateFilterText = (filters: string[]) => {
    if(filters.length === 0) {
        return "";
    } else if(filters.length === 1) {
        return <span> og filtervalget {renderHighlightedSpan(filters[0], 1)}</span>
    } else {
        let lastItem = filters.pop();
        return <span className="lowercase"> og filtervalgene {joinChildren(filters, renderHighlightedSpan, renderSeperator)} og <span className="highlighted">{lastItem}</span></span>
    }
}
const renderSeperator = (key) => {
    return ', '
}
const renderHighlightedSpan = (text, index) => {
    return <span key={`filterError-${index}`} className="highlighted">{text}</span>;
}

function joinChildren(children, render, renderSeparator) {
    return children.reduce((result, child, index) => {
      if (index < children.length - 1) {
        return result.concat([render(child, index), renderSeparator(index + '-separator')])
      }

      return result.concat(render(child, index))
    }, [])
}

export default NoResultPane;
