import React, { FC } from 'react';
import './Result.scss';
import { IResultProps } from './IResultProps';
import { IGlobalResult } from '../../../../Models/IResult';
import { getLanguage } from '../../../../utils/searchHelpers';
//import { handleLinkClick} from '../../../../utils/recordPageClickHandler';
import format from 'date-fns/format';


const Result: FC<IResultProps> = (props) => {
    let { result } = props;
    let language_attr = getLanguage(result);
    const displayTitle = result.udirtitle ? result.udirtitle : result.title;
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        //const queryText = props.queryText || '';
        //handleLinkClick(result.Url, result, queryText);
    };
    
    return (
        <div className="ResultPane" key={`resultDisplay-${props.idx}`}>
            <a className="resultLink" href={result.Url} rel="noopener" onClick={handleClick}>
                <h2 key={`resultHeader-1`} className="no-margin-top resultLink-title" {...language_attr}>{displayTitle}</h2>
            </a>
            {getTeaser(result)}
            <div className="resultSubheader">
                {getMetadata(result)}
            </div>
            {shouldDisplayAttachments(result) && <Attachements result={result} />}
        </div>
    )
}
const shouldDisplayAttachments = (result: IGlobalResult) => {
    if(result.vedlegg && result.vedlegg !== null){ 
        return true;
    }else{
        return false;
    }
}

const getTeaser = (result: IGlobalResult) => {
    // Eksamensmateriell has no teaser 
    if(result.Url.indexOf("https://kandidat") === 0 || result.Url.indexOf("https://eksamen") === 0 || result.Url.indexOf("https://testuespudir") === 0){
        return null;
    }
    let metadataElements: React.ReactElement[] = [];
    let language_attr = getLanguage(result);
    metadataElements.push(<p key="resultData-teaser" {...language_attr} dangerouslySetInnerHTML={{__html: result.hhteaser}} />);
    return metadataElements;
}

const getMetadata = (result: any) => {
    let metadataElements: React.ReactElement[] = [];
    let hasContentType:boolean = false;

    /* Eksamen metadata */
    if(result.ContentSource && result.ContentSource === "Doklager"){
        // Innholdstype
        if(result.eksamenstype){
            metadataElements.push(<span key="resultData-meta-Ex-1" className="capitalLetters">{result.eksamenstype}</span>);
        }
        // Sentralgitt / Lokalgitt (Ansvarsområde)
        if (result.ExAnsvarstype && result.ExAnsvarstype !== "") {
            metadataElements.push(<span className="resultDivider" key="resultDivider-Ex-1" />);
            metadataElements.push(<span key="resultData-meta-Ex-2">{result.ExAnsvarstype}</span>);
        }
        // Kunnskapsløftet 2006/2020
        if (result.ExKL && result.ExKL !== "") {
            let privatistText = "";
            if(result.ExKL === "Kunnskapsløftet 2006") {
                privatistText = " (kun for privatister)";
            }
            metadataElements.push(<span className="resultDivider" key="resultDivider-Ex-2" />);
            metadataElements.push(<span key="resultData-meta-Ex-3">{result.ExKL}{privatistText}</span>);
        }
        // Periode
        if (result.periode && result.periode!=="undefined-") {
            metadataElements.push(<span className="resultDivider" key="resultDivider-Ex-3" />);
            metadataElements.push(<span key="resultData-meta-Ex-4">{result.periode}</span>);
        }
        // Målform
        if (result.maalform && result.maalform !== "Bokmål/nynorsk") {
            metadataElements.push(<span className="resultDivider" key="resultDivider-Ex-4" />);
            metadataElements.push(<span key="resultData-meta-Ex-5">{result.maalform}</span>);
        }
        // Lenke til læreplan
        let regexFagkode = new RegExp('[A-ZØÆÅ]{3}[0-9]{4}');
        let privatistTextFag = "";
        if(result.ExKL === "Kunnskapsløftet 2006") {
            privatistTextFag = " (kun for privatister)";
        }
        let fagkodeText = (result.fagkode && result.fagkode.length < 10) ? "fagkode" : "fagkoder";
        let fagkodeString = (result.fagkode) ? result.fagkode.replace(/(,)([A-ZÆØÅ])/gi, '$1 $2') : "";
        if (result.fagkode && result.fagkode.length > 10) {
            fagkodeString = fagkodeString.split(",").join(privatistTextFag+",");
        }
        fagkodeString += privatistTextFag;
        if (result.laereplanurl) {
            metadataElements.push(<span className="resultDivider" key="resultDivider-Ex-5" />);
            if(!regexFagkode.test(result.fagkode)){
                metadataElements.push(<span key="resultData-meta-Ex-6"><a href={`https://sokeresultat.udir.no/finn-lareplan.html?query=${result.fagkode.toLowerCase()}&fltypefiltermulti=Læreplan`} className="resultLink underline">Søk etter læreplan</a> for {result.eksamenstype.toLowerCase()} i {result.fagkode.toLowerCase()}</span>)
            }else{
                metadataElements.push(<span key="resultData-meta-Ex-6"><a href={result.laereplanurl} className="resultLink underline">Læreplan</a> for tilhørende {fagkodeText}: {fagkodeString}</span>)
            }
        } else if (result.fagkode) {
            // Eksempeloppgave from udir.no
            metadataElements.push(<span className="resultDivider" key="resultDivider-Ex-5" />);
            if(!regexFagkode.test(result.fagkode)){
                metadataElements.push(<span key="resultData-meta-Ex-6"><a href={`https://sokeresultat.udir.no/finn-lareplan.html?query=${result.fagkode.toLowerCase()}&fltypefiltermulti=Læreplan`} className="resultLink underline">Søk etter læreplan</a> for {result.eksamenstype.toLowerCase()} i {result.fagkode.toLowerCase()}</span>)
            }else if(result.laereplankode && result.laereplankode.length>1){
                metadataElements.push(<span key="resultData-meta-Ex-6"><a href={`https://www.udir.no/lk20/${result.laereplankode}`} className="resultLink underline">Læreplan</a> for tilhørende {fagkodeText}: {fagkodeString}</span>)
            }else{
                metadataElements.push(<span key="resultData-meta-Ex-6">Tilhørende {fagkodeText}: {fagkodeString}</span>)
            }
        }
    }else{
        /* Standard global metadata */
        if(result.innholdstype) {
            metadataElements.push(<span key="resultData-meta-1" className="capitalLetters">{result.innholdstype}</span>)
            hasContentType = true;
        }
        if(result.datoendret) {
            if(hasContentType) {
                metadataElements.push(<span className="resultDivider" key="resultDivider-1"/>);
            }
            if(result.innholdstype && result.innholdstype !== "Tema"){ 
                let dateFormatted = format(new Date(result.datoendret), 'dd.MM.yyyy');
                metadataElements.push(<span key="resultData-meta-2">{dateFormatted}</span>);
            }
        }
    }

    // Removing last element if it is a resultDivider 
    if (metadataElements.length > 0) {
        const lastElement = metadataElements[metadataElements.length - 1];
        if (lastElement && lastElement.props && lastElement.props.className === "resultDivider") {
          metadataElements.pop();
        }
    }
    
    return metadataElements;
}

const Attachements: React.FunctionComponent<{result: IGlobalResult }> = (props: {result: IGlobalResult }) => {
    let {result} = props;
    if(!result.vedlegg || result.vedlegg.length === 0) return null;
    return (
        <ul className="vedleggliste">
            <li key="attachementHeader" className="listheader">Vedlegg:</li>
            {result.vedlegg.map(itemHTML => {
                return(
                    <li key={`attchement-${1}`} dangerouslySetInnerHTML={{__html: itemHTML}} />
                );
            })}
        </ul>
    );
}
export default Result;
