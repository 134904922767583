import React, { FunctionComponent } from 'react';
import { INavigationProps } from './INavigationProps';
import './Navigation.scss';

const Navigation: FunctionComponent<INavigationProps> = (props) => {
    let activeclassObject = getActiveClasses(props);
    let navItemCaption_classNames = "navItem-caption ";
    return (
        <nav role="navigation" aria-label="Spesialsøk" id="navigationContainer" className="navigationContainer">
            <ul className="navItems">
                <li className={`navItem ${activeclassObject.alle}`}>
                    {/*
                    <svg xmlns="http://www.w3.org/2000/svg" id="alle_svg" viewBox="0 0 24 24" width="13" height="13">
                        <path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z"/>
                    </svg>
                    */}
                    <a  href={'/?query=' + props.searchQuery} 
                        className={`navItem-link navItem-link-alle ${activeclassObject.alle}`}
                        aria-label='Søk i alt på udir.no' 
                        aria-current={activeclassObject.alle==='active' ? 'page':'false'} >
                            <span className={`${navItemCaption_classNames}`}>Alle</span>
                    </a>
                </li>
                <li className={`navItem ${activeclassObject.laereplan}`}>
                    {/*
                    <svg id="laereplan_svg" height="13" width="13" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
                        <path d="m19.535 3.122-1.656-1.658a4.968 4.968 0 0 0 -3.536-1.464h-6.343a5.006 5.006 0 0 0 -5 5v14a5.006 5.006 0 0 0 5 5h8a5.006 5.006 0 0 0 5-5v-12.343a4.968 4.968 0 0 0 -1.465-3.535zm-1.414 1.414a2.932 2.932 0 0 1 .379.464h-2.5v-2.5a3.1 3.1 0 0 1 .465.38zm.879 14.464a3 3 0 0 1 -3 3h-8a3 3 0 0 1 -3-3v-14a3 3 0 0 1 3-3h6v3a2 2 0 0 0 2 2h3zm-3-10a1 1 0 0 1 0 2h-8a1 1 0 0 1 0-2zm1 5a1 1 0 0 1 -1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm-.192 3.413a1 1 0 0 1 -.217 1.394 6.464 6.464 0 0 1 -3.456 1.193 3.252 3.252 0 0 1 -2-.7c-.328-.225-.453-.3-.7-.3a3.951 3.951 0 0 0 -1.832.794 1 1 0 0 1 -1.214-1.588 5.861 5.861 0 0 1 3.05-1.206 3.025 3.025 0 0 1 1.832.655 1.347 1.347 0 0 0 .864.345 4.586 4.586 0 0 0 2.277-.809 1 1 0 0 1 1.396.222z"/>
                    </svg>
                    */}
                    <a  href={'/finn-lareplan.html?query=' + props.searchQuery + '&fltypefiltermulti=Læreplan&filtervalues=all'} 
                        className={`navItem-link navItem-link-laereplan ${activeclassObject.laereplan}`}
                        aria-label='Søk i læreplaner'
                        aria-current={activeclassObject.laereplan==='active' ? 'page':'false'} >
                            <span className={`${navItemCaption_classNames}`}>Læreplan</span>
                    </a>
                </li>
                <li className={`navItem ${activeclassObject.eksamen}`}>
                    {/*
                    <svg id="eksamen_svg" height="13" width="13" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
                        <path d="m16 17a1 1 0 0 1 0 2h-1a1 1 0 0 1 0-2zm-2-2a1 1 0 0 1 -1-1v-1h-2v1a1 1 0 0 1 -2 0v-4a3 3 0 0 1 6 0v4a1 1 0 0 1 -1 1zm-1-4v-1a1 1 0 0 0 -2 0v1zm-1.711 5.3-1.612 1.63a.25.25 0 0 1 -.344.01l-.616-.64a1 1 0 0 0 -1.434 1.4l.626.644a2.255 2.255 0 0 0 3.186 0l1.616-1.644a1 1 0 0 0 -1.422-1.4zm9.711-9.643v12.343a5.006 5.006 0 0 1 -5 5h-8a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h6.343a4.969 4.969 0 0 1 3.536 1.465l1.656 1.656a4.969 4.969 0 0 1 1.465 3.536zm-4.535-3.778a3.042 3.042 0 0 0 -.465-.379v2.5h2.5a3.042 3.042 0 0 0 -.38-.465zm2.535 4.121h-3a2 2 0 0 1 -2-2v-3h-6a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3z"/>
                    </svg>
                    */}
                    <a  href={'/eksamensoppgaver.html?query=' + props.searchQuery} 
                        className={`navItem-link navItem-link-eksamen ${activeclassObject.eksamen}`}
                        aria-label='Søk i eksamensoppgaver'
                        aria-current={activeclassObject.eksamen==='active' ? 'page':'false'} >
                            <span className={`${navItemCaption_classNames}`}>Eksamensoppgaver</span>
                    </a>
                </li>
                <li className={`navItem ${activeclassObject.forskning}`}>
                    {/* 
                    <svg xmlns="http://www.w3.org/2000/svg" id="forskning_svg" data-name="Layer 1" viewBox="0 0 24 24" width="13" height="13">
                        <path d="M23,22H5a3,3,0,0,1-3-3V1A1,1,0,0,0,0,1V19a5.006,5.006,0,0,0,5,5H23a1,1,0,0,0,0-2Z"/><path d="M6,20a1,1,0,0,0,1-1V12a1,1,0,0,0-2,0v7A1,1,0,0,0,6,20Z"/><path d="M10,10v9a1,1,0,0,0,2,0V10a1,1,0,0,0-2,0Z"/><path d="M15,13v6a1,1,0,0,0,2,0V13a1,1,0,0,0-2,0Z"/><path d="M20,9V19a1,1,0,0,0,2,0V9a1,1,0,0,0-2,0Z"/><path d="M6,9a1,1,0,0,0,.707-.293l3.586-3.586a1.025,1.025,0,0,1,1.414,0l2.172,2.172a3,3,0,0,0,4.242,0l5.586-5.586A1,1,0,0,0,22.293.293L16.707,5.878a1,1,0,0,1-1.414,0L13.121,3.707a3,3,0,0,0-4.242,0L5.293,7.293A1,1,0,0,0,6,9Z"/>
                    </svg>
                    */}
                    <a  href={'/forskning-og-rapporter.html?query=' + props.searchQuery} 
                        className={`navItem-link navItem-link-forskning ${activeclassObject.forskning}`}
                        aria-label='Søk i forskning og statistikk'
                        aria-current={activeclassObject.forskning==='active' ? 'page':'false'}>
                            <span className={`${navItemCaption_classNames}`}>Forskning og statistikk</span>
                    </a>
                </li>
                <li className={`navItem ${activeclassObject.regelverk}`}>
                    <a  href={'/regelverkstolkninger.html?query=' + props.searchQuery} 
                        className={`navItem-link navItem-link-regelverk ${activeclassObject.regelverk}`}
                        aria-label='Søk i regelverk'
                        aria-current={activeclassObject.regelverk==='active' ? 'page':'false'}>
                            <span className={`${navItemCaption_classNames}`}>Regelverk</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

const getActiveClasses = (props) => {
    let classObject = {
        alle: "",
        laereplan: "",
        eksamen: "",
        forskning: "",
        regelverk: ""
    };
    let pathName = document.location.pathname;
    if(pathName === "/") {
        classObject.alle = "active";
    } else if(pathName === "/finn-lareplan.html") {
        classObject.laereplan = "active";
    } else if(pathName === "/eksamensoppgaver.html") {
        classObject.eksamen = "active";
    } else if(pathName === "/forskning-og-rapporter.html") {
        classObject.forskning = "active";
    } else if(pathName === "/regelverkstolkninger.html") {
        classObject.regelverk = "active";
    } 
    return classObject;
}


export default Navigation;