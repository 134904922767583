//import React from 'react';
import ReactDOM from 'react-dom/client';
import footerProps from './footer-menudata.json';


export default function footerMounter(footerRoot?: ReactDOM.Root, dynamicFooterProps?: any) {
    let components = (window as any).UdirComponents;
    let footerData = dynamicFooterProps !== undefined ? dynamicFooterProps :  footerProps;
    //let footerElement = React.createElement(components.Footer, footerData);
    //footerRoot.render(footerElement);
    (window as any).ReactDOM.render((window as any).React.createElement(components.Footer, footerData), document.getElementById('footer'));
}
