import IRefiners from "../Models/IRefiners";
import IRefiner from "../Models/IRefiner";
import {getFilterParametersFromUrl} from "./urlParamHelper"

export function addNonActiveFilters(refiners, isChecked?: boolean) {
    let statusFilter = refiners.filter(refiner => refiner.Name === "status")[0];
    if(!statusFilter) {
        statusFilter = {
            Name: "Status",
            Entries: []
        };
        refiners.push(statusFilter);
    }
    let upcomingEntry = statusFilter.Entries.filter(entry => entry.RefinementValue === "Kommende")[0];
    if(upcomingEntry) {
        upcomingEntry.RefinementName = "Inkludér kommenede versjoner";
    } else {
        statusFilter.Entries.push({
            RefinementCount: undefined,
            RefinementName: "Inkludér kommenede versjoner",
            RefinementToken: ConvertStringToSearchToken("Kommende"),
            RefinementValue: "Kommende",
            checked: isChecked
        });
    }
    let expiredEntry = statusFilter.Entries.filter(entry => entry.RefinementValue === "Utgått")[0];
    if(expiredEntry) {
        expiredEntry.RefinementName = "Inkluder utgåtte versjoner";
    } else {
        statusFilter.Entries.push({
            RefinementCount: undefined,
            RefinementName: "Inkluder utgåtte versjoner",
            RefinementToken: ConvertStringToSearchToken("Utgått"),
            RefinementValue: "Utgått",
            checked: isChecked
        });
    }
    return refiners;
}

/**
 * Function that sets active search filters from URL and expands all available refiners for predefined search.
 * @param refiners Refiners for search results
 * @param urlFilters Refiners from URL
 * @param newResultsRefiners Refiners for new search results
 * @returns Refiners with updated checked entries (as defined in URL param)
 */
export function addActiveAndUnactiveFiltersFromUrl(refiners, urlFilters, newResultsRefiners) {
    urlFilters.forEach(elem => {
        // Checking if refiner from URL param exists
        let myFilter = refiners.filter(refiner => refiner.Name === elem.Name)[0];
        if(!myFilter) {
            myFilter = {
                Name: elem.Name,
                Entries: []
            };
            refiners.push(myFilter);
        }
        
        elem.Entries.forEach(item => {
            // Getting index for refiner item to update as selected/checked
            let objIndex = myFilter.Entries.findIndex(elem => elem.RefinementName === item.RefinementName);
            // Setting selected/checked
            if(myFilter.Entries[objIndex]){
                myFilter.Entries[objIndex].checked = true;
            }
            
        });

        /*
        // Update RefinementCounts when more than one selected filters in URL params?
        refiners.forEach(refiner => {
            let refinerIsSelected = urlFilters.filter(e => e.Name === refiner.Name);
            if(refinerIsSelected.length > 0){
                console.log(refinerIsSelected);
                console.log(newResultsRefiners);
                let refinerMaxCount = newResultsRefiners[0].Entries[0].RefinementCount;
                // Refiner is selected. Update with hit count
                refiner.Entries.forEach(element => {
                    element.RefinementCount = refinerMaxCount;
                });
            }else if(newResultsRefiners){
                let newResultsRefiner = newResultsRefiners.filter(e => e.Name === refiner.Name);
                let objIndex = refiners.findIndex((obj => obj.Name === refiner.Name));
                refiners[objIndex] = newResultsRefiner[0];
            }
        });
        */ 
        
    });
    
    return refiners;
}

export function addActiveFiltersFromUrl(refiners, urlFilters) {
    urlFilters.forEach(elem => {
        let myFilter = refiners.filter(refiner => refiner.Name === elem.Name)[0];
        if(!myFilter) {
            myFilter = {
                Name: elem.Name,
                Entries: []
            };
            refiners.push(myFilter);
        }
        elem.Entries.forEach(item => {
            myFilter.Entries.push({
                RefinementCount: undefined,
                RefinementName: item.RefinementName,
                RefinementToken: item.RefinementToken,
                RefinementValue: item.RefinementValue,
                checked: true,
            });
        });

    });
    return refiners;
}

export function getFilterNames(refiners: IRefiners[]) {
    return refiners.map(refiner => {
        let checkedRefiners = refiner.Entries.filter(entry => entry.checked);
        return checkedRefiners.map(checkedRefiner => checkedRefiner.RefinementValue);
    }).reduce((acc, val) => acc.concat(val), []);
}



export function ConvertStringToSearchToken(str) {
    var encoded = '';
    for (var i = 0; i < str.length; i++) {
        var character = str.charAt(i);
        var encodedChar = encodeURIComponent(character);
        if (character !== encodedChar) {
            encoded += encodedChar.replace(/%/g, "").toLowerCase();
        } else {
            encoded += parseInt(character.charCodeAt(0)).toString(16);
        }
    }
    return "\"ǂǂ" + encoded + "\"";
}

export function ConvertDateStringInUrlToSearchToken(str: string) {
    // Variants:
    // 1) Before 2016-06-25T20:04:35.5000320Z --> range(min, 2016-06-25T20:04:35.5000320Z)
    // 2) From 2016-06-25T20:04:35.5000320Z up to 2019-12-13T13:07:27.5000832Z --> range(2016-06-25T20:04:35.5000320Z, 2019-12-13T13:07:27.5000832Z)
    // 3) 2021-03-15T14:53:22.4999936Z or later --> range(2021-03-15T14:53:22.4999936Z, max, to="le")
    var encoded = 'range';
    
    if(str.startsWith("Before ")){
        encoded = encoded + "(min, " + str.substring(7) + ")";
    }
    if(str.startsWith("From") && str.indexOf(" up to ") !== -1){
        encoded = encoded + "(" + str.substring(5,str.indexOf(" up to ")) +", "+ str.substring(str.indexOf(" up to ")+7) + ")";
    }
    if(str.endsWith(" or later") ){
        encoded = encoded + "(" + str.substring(0,str.indexOf(" or later")) +", max, to=\"le\")";
    }
    return encoded;
}


export function ensureSelectedFilterValues(nextFilter: IRefiners[], selectedFilter: IRefiners, refinerName: string) {
    if (!nextFilter) return nextFilter;
    for (let _i = 0; _i < nextFilter.length; _i++) {
        if (nextFilter[_i].Name === refinerName) {
            if (nextFilter[_i].Entries.length < selectedFilter.Entries.length) {
                nextFilter[_i] = selectedFilter;
            } else {
                cloneRefinerCounts(selectedFilter.Entries, nextFilter[_i].Entries);
            }
        }
    }
    return nextFilter
}

export function cloneRefinerCounts(cloneFrom: IRefiner[], cloneTo: IRefiner[]) {
    if(cloneFrom.length !== cloneTo.length) return cloneTo;
    return cloneTo.map((refiner, index) => {
        refiner.RefinementCount = cloneFrom[index].RefinementCount;
        return refiner;
    });
}

export function getNumberOfActiveFilters(state_filter: any, refinementFilters: any): number {
    let refiners = state_filter;
    if (!refiners) {
        return 0
    }
    let numberOfFilters = 0;
    refiners.forEach(refiner => {
        let checkedRefinerValues = refiner.Entries.filter(refinerValue => refinerValue.checked);
        numberOfFilters += checkedRefinerValues.length;
    });
    if(numberOfFilters === 0){
        // Checking if init query and filters are set in URL
        let initFiltersInURL = getFilterParametersFromUrl(refinementFilters);
        numberOfFilters = initFiltersInURL.length ? initFiltersInURL.length : 0; 
    }
    
    return numberOfFilters;
}

export function sortRefiners(a: IRefiner, b: IRefiner, orderList: string[]) {
    let aOrder = orderList.indexOf(a.RefinementName);
    let bOrder = orderList.indexOf(b.RefinementName);

    //Check if they are defined in the sortlist, if not, send them to the back
    if (aOrder !== -1 && bOrder !== -1) {
        return aOrder - bOrder;
    } else if (aOrder === -1 && bOrder === -1) {
        return a.RefinementName.localeCompare(b.RefinementName);
    }
    else if (aOrder === -1) {
        return 1
    } else if (bOrder === -1) {
        return -1
    } else {
        return a.RefinementName.localeCompare(b.RefinementName);
    }
}

/**
 * Function that renames the RefinementName (filter value) for a given refiner.
 * @param refiner The refiner that contains the filtervalue that is to be changed
 * @param fromValue The current filter value
 * @param toValue The new filter value
 * @returns The refiner with with the updated filter values
 */
export function renameRefinementName(refiner: IRefiners, fromValue: string, toValue: string) : IRefiners{
    refiner.Entries.forEach(refinement => {
        if(refinement.RefinementName === fromValue){
            refinement.RefinementName = toValue;
        }
    });
    return refiner;
}