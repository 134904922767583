import React, { FunctionComponent } from 'react';
import IResponse from '../../Models/IResponse';
import IRefiners from '../../Models/IRefiners';
import { getFilterParametersFromUrl } from '../../utils/urlParamHelper';
import { Log } from '../../utils/logging';


export interface ISuggestionBoxProps {
    query?: string;
    searchboxQuery?: string;
    erronousQuery?: string;
    querySuggestion?: string;
    emptyFilters: () => void;
    initialFilters?: IRefiners[];
    refinementFilters?: string[];
    searchWithoutFiltersHasResults?: boolean;
    isSearchingWithDeactivatedFilters?: boolean;
    results?: IResponse;
}



export const SuggestionBox: FunctionComponent<ISuggestionBoxProps> =
    ({ query, searchboxQuery, erronousQuery, querySuggestion, emptyFilters, initialFilters, refinementFilters, searchWithoutFiltersHasResults, isSearchingWithDeactivatedFilters, results }) => {
        const displaySearchWithoutFilterGiveResults = searchWithoutFiltersHasResults;

        if(results?.totalResults === 0 && results?.totalResultsIncludingDuplicates === 0) {
            return <div />;
        }
        
        if (displaySearchWithoutFilterGiveResults) {
            let activeFilters = joinChildren(initialFilters);
            if(refinementFilters !== undefined && activeFilters.length === 0){
                let urlFilters = getFilterParametersFromUrl(refinementFilters);
                activeFilters = joinChildren(urlFilters);
            }
            return (
                <div className="emptyResultBanner">
                    <span>Ingen resultater for </span>
                    <span className="uthevet">{searchboxQuery}</span>
                    <span> med filtervalgene </span>
                    <span className="uthevet lowercase">
                        {activeFilters}.
                    </span>
                    <p></p>
                    <span>Viser resultater for </span>
                    <span className="uthevet">
                        <button onClick={emptyFilters}>{query}</button>
                    </span>
                    <span> uten filtervalg.</span>
                </div>
            );
        }
        const displaySuggestionBox = querySuggestion && querySuggestion.length > 0;
        if (displaySuggestionBox) {
            if (initialFilters && initialFilters.length > 0) {
                return (
                    <div className="emptyResultBanner">
                        <span>Ingen resultater for </span>
                        <span className="uthevet">{erronousQuery}</span>
                        <span> med filtervalgene </span>
                        <span className="uthevet lowercase">
                            {joinChildren(initialFilters)}.
                        </span>
                        <p></p>
                        <span>Viser resultater for </span>
                        <span className="uthevet">{querySuggestion}</span>
                        <span> med filtervalgene </span>
                        <span className="uthevet lowercase">
                            {joinChildren(initialFilters)}.
                        </span>
                    </div>
                );
            } else {
                return (
                    <div className="emptyResultBanner">
                        <span>Ingen resultater for </span>
                        <span className="uthevet">{erronousQuery}</span>
                        <span>. Viser resultater for </span>
                        <span className="uthevet">{querySuggestion}.</span>
                    </div>
                );
            }
        }
        const displayInactiveFilterBox = isSearchingWithDeactivatedFilters;
        if (displayInactiveFilterBox) {
            if (initialFilters && initialFilters.length > 0) {
                let filtervalgText = initialFilters.length === 1 ? "filtervalget" : 'filtervalgene';
                return (<div className="emptyResultBanner">
                    <span>Ingen resultater for </span>
                    <span className="uthevet">
                        {query}
                    </span>
                    <span> med {filtervalgText} </span>
                    <span className="uthevet">
                        {joinChildren(initialFilters)}.
                    </span>
                    <p></p>
                    <span>Viser resultater for </span>
                    <span className="uthevet">{query}, inkludert utgåtte versjoner.</span>
                </div>
                );
            } else {
                return (<div className="emptyResultBanner">
                    <span>Ingen resultater for </span>
                    <span className="uthevet">
                        {query}
                    </span>
                    <span>. Viser resultater for </span>
                    <span className="uthevet">{query}, inkludert utgåtte versjoner.</span>
                </div>
                );
            }
        }
        const queryModifiedByQueryRule = (results && results.modifiedQuery && results.modifiedQuery.length > 0) ? true : false;
        if (queryModifiedByQueryRule) {
            if (results && results.modifiedQuery && results.modifiedQuery.length > 0) {
                return (
                    <div className="emptyResultBanner">
                        <span>Ingen resultater for </span>
                        <span className="uthevet">{query}</span>
                        <span>. Viser resultater for </span>
                        <span className="uthevet">{results.modifiedQuery}.</span>
                    </div>
                );
            }
        }
        return <div />;
    }


function renderSeparator() {
    return ', '
}

function renderHighlightedSpan(text, index) {
    return <span key={`filterError-${index}`} className="highlighted">{text}</span>;
}

function joinChildren(filters) {
    let children: any[] = [];
    Log(filters);
    if(filters !== undefined){
        filters.forEach(refinementFilter => {
            refinementFilter.Entries.forEach(entry => {
                children.push(entry.RefinementValue);
            });
        });
    }
    return children.reduce((result, child, index) => {
        if (index < children.length - 1) {
            return result.concat([renderHighlightedSpan(child, index), renderSeparator()])
        }

        return result.concat(renderHighlightedSpan(child, index))
    }, [])
}
