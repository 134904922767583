import React from 'react';
import ReactDOM from 'react-dom/client';
import GlobalSok from './Components/GlobalSok/GlobalSok';
import setupHeaderAndFooter, {SearchSite} from './utils/setupHeaderAndFooter';
import 'udirsok-index.js'

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render( <GlobalSok/> );

setupHeaderAndFooter(SearchSite.Global);
