import { DisplayFormat } from '../Models/DisplayFormat';

export function getCurrentDisplayFormat(): DisplayFormat {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1024) {
        return DisplayFormat.Desktop
    } else if (windowWidth >= 768) {
        return DisplayFormat.Tablet
    }
    return DisplayFormat.Mobile
}