import { Log } from './logging';

async function getSearchSuggestions(query: string, numhits?: number, source?: string) {
    const response = await searchforquery(query, numhits, source);
    const result = await response.text();
    if(!result || result === null || result === "" || result === undefined || result === "Hello World"){ return []; }

    var searchsuggestions_json = JSON.parse(result);
    Log(searchsuggestions_json.Queries);
    var queries_obj = searchsuggestions_json.Queries;
    let searchsuggestions_array : string[] = [];
    queries_obj.forEach(elem => {
        if(elem){
            searchsuggestions_array.push(elem.Query);
        }
    });
    Log(searchsuggestions_array);
    return searchsuggestions_array;
}

function searchforquery(query, numhits, source) {
    Log("searchforquery - Autocomplete.ashx");
    
    let protocol = window.location.protocol;
    let hostname = window.location.hostname;
    if(hostname === "localhost"){ 
        protocol = "https:";
        hostname = "dev-sokeresultat.udir.no";
    }else if(hostname === "qa-sokeresultat.udir.no"){ 
        protocol = "https:";
        hostname = "sokeresultat.udir.no";
    }
    const encodedQuery = encodeURIComponent(query);
    let url = protocol + '//' + hostname + '/Autocomplete.ashx?query=' + encodedQuery + "&numberofquerysuggestions=" + numhits;
    if(source && source !== ""){
        url += "&source=" + source; 
    }
    return fetch(url, { headers: { 'Accept': 'application/json;odata=nometadata;charset=utf-8' } });
}


export default getSearchSuggestions;
